import { Injectable, Inject } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { SignalRAdapter } from '../components/signalRAdapter/signalr-adapter';
declare var jQuery: any;
@Injectable()
export class StorageService {
  constructor() {
    console.log("constructor: :  StorageService ");

  }
  contentComponentIdList: any = [];

  add(key: string, value: any) {
    console.log("key: ", key);
    this.remove(key);
    if (value) {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  get<T>(key: string): T {
    let value: string = localStorage.getItem(key);

    if (value && value !== "undefined" && value !== "null") {
      try {
        return <T>JSON.parse(value);
      } catch (ex) {
        return null;
      }
    }
    return null;
  }

  remove(key: string) {
    console.log("remove is called!!");
    localStorage.removeItem(key);
  }

  public hubConnection: signalR.HubConnection;
  public otherEndChatId:any;
  public SignalRAdapter: SignalRAdapter;
  getconnection() {
    return this.hubConnection;
  }
  // theme change on dropdown------

  // text style //
  public globalprescription : any;

  getprescription() {

    return this.globalprescription;
  }
  public Issync: boolean;
  public hubmessage: any;
  public isConnectionEstablished: boolean=false;
  public isLoaderShow: boolean = false;
  public consultationId: any;
  public userId: any;
  public FromHospitalName: any;
  public Currenturl: any;

  public SenderId:any;
  public ReceiverId:any
  public showpop: boolean = false;
  public ddlshow: boolean = false;
  public singnalrstatus: boolean = false;
  public btnsavedraftStatus: boolean = false;
  public isDraftStatus: boolean=false;
  public isConsultationActive: boolean=false;
  public remoteType: boolean=false;
}
