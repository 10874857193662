import { Component, Input } from '@angular/core';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';


@Injectable()
export class AppServices {

  constructor(private http: Http) {
    var obj;
    this.getJSON();
  }

  public getJSON(): Observable<any> {
    return this.http.get("./assets/Branch_Format.xlsx");


  }
}