import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { first, filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../_services';
import { DAuthenticationService } from '../../../_services/doctor/index';
import { DUserService } from '../../../_services/doctor/index';

//import { SignalRAdapter } from '../../signalRAdapter/signalr-adapter';
//import { SignalRGroupAdapter } from '../../signalRAdapter/signalr-group-adapter';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { SignalRAdapter } from '../../signalRAdapter/signalr-adapter';
import { StorageService } from '../../../_services/index';
import { IChatController } from 'src/app/ng-chat/core/chat-controller';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EventEmitterService } from '../../../_services/event-emitter.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ConnectionService } from 'ng-connection-service';
import { Message } from '../../../ng-chat/core/message';
import 'sweetalert2/src/sweetalert2.scss';
import { UserIdleService } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from '../../../_helpers/config';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-doctorlayout',
  templateUrl: './doctorlayout.component.html',
  styleUrls: ['./doctorlayout.component.css']
})
export class DoctorlayoutComponent implements OnInit {
  public serverBaseUrl: string;
  returnUrl: string;
  profilepath: any;
  doctorname: string;
  hospitalname: string;
  menulist: any;
  title = 'Online Doctors';
  currentTheme = 'light-theme';
  triggeredEvents = [];
  fileUploadUrl: string;
  searchPlaceholder: string = "Search Available Doctor List"
  userId: string = "offline-demo";
  username: string;
  signalRAdapter: SignalRAdapter; //SignalRGroupAdapter;

  AudioUnMute: boolean = false;
  AudioMute: boolean = true;
  VideoUnMute: boolean = false;
  VideoMute: boolean = true;
  lasturl: string;
  Connectedstatus = '';
  isConnected = true;
  isCollapsed = false;
  mainBody = 'body_inner adminbody';
  mainClass = 'forced';

  loding: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: DAuthenticationService,
    private _userService: DUserService,
    public storageService: StorageService,
    private eventEmitterService: EventEmitterService,
    private alertService: AlertService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private connectionService: ConnectionService,
    private toastr: ToastrManager,
    private userIdle: UserIdleService,
    private cookieService: CookieService,
    private domSanitizer: DomSanitizer

  ) {
    this.serverBaseUrl = baseUrl;

    this.fileUploadUrl = `${this.serverBaseUrl + "api/Home/"}UploadFile`;




  }




  ngOnInit() {




    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.Connectedstatus = "";
      } else {
        this.Connectedstatus = "You are not connected to the internet. Please check your connection and try again.";
      }

    })

    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeFirstComponentFunction.subscribe((name: string) => {
          this.firstFunction();
        });
    }


    //this.router.navigateByUrl(this.router.url);
    this.lasturl = this.router.url;

    this.profilepath = 'assets/images/avatars/admin.png';

    var menustatus = JSON.parse(localStorage.getItem('MenuStaus'));
    if (!menustatus) {
      this.mainBody = 'body_inner adminbody';
      this.mainClass = 'forced';

    }
    else {
      this.mainBody = 'body_inner adminbody-void';
      this.mainClass = 'forced enlarged';

    }

    var localstore = JSON.parse(localStorage.getItem('currentUser'));
    var usertype = localStorage.getItem('currentUserType');

    if (localstore == null || usertype == null) {

      window.location.href = "/Login";
      // Swal.fire({
      //   title: 'Oops...',
      //   text: 'Invalid Request!',
      //   type: 'error',
      //   showCancelButton: false,
      //   confirmButtonColor: '#133656',
      //   cancelButtonColor: '#E5C151',
      //   confirmButtonText: 'Ok!',
      //   allowOutsideClick: false,
      //   allowEscapeKey: false,
      //   allowEnterKey: false

      // }).then((result) => {
      //   if (result.value) {



      //     window.location.href = "/Login";
      //     this.storageService.SignalRAdapter.CloseChatGrid(this.storageService.otherEndChatId);
      //     this.storageService.isConnectionEstablished = false;
      //     this.storageService.SignalRAdapter.LeaveConnference();


      //     //this.router.navigateByUrl('Hospital/Login');
      //   }
      // })
    }



    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';



    if (localstore.success) {
      if (localstore.model.imagePath) {

        this.profilepath = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,' + localstore.model.imagePath);


      }



      this.hospitalname = localstore.model.institutionName;

      //this.doctorname = localstore.model.prefix+" "+localstore.model.firstName + " " + localstore.model.middleName + " " + localstore.model.lastName;

      if (localstore.model.prefix == "Dr.") {

        this.doctorname = localstore.model.prefix + " " + localstore.model.firstName + " " + localstore.model.middleName + " " + localstore.model.lastName;

      } else {
        this.doctorname = localstore.model.firstName + " " + localstore.model.middleName + " " + localstore.model.lastName;

      }



      this.menulist = localstore.model.lstSubMenuModel;

      for (let i = 0; i < this.menulist.length; i++) {
        if (this.menulist[i].pathUrl == this.router.url) {
          this.menulist[i].isActive = true;
        } else {
          this.menulist[i].isActive = false;
        }
      }



      this.storageService.showpop = false;
      this.storageService.ddlshow = false;

      if (this.storageService.hubConnection == undefined) {
        this.joinSignalRChatRoom();
      }
      else {

        this.signalRAdapter = this.storageService.SignalRAdapter;

      }

    }




    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {



        if (e.url.includes("Doctor/Consulatationdetail")) {



          Swal.fire({
            title: '',
            text: "Please wait",
            imageUrl: 'https://esanjeevani.in/assets/images/3.gif',
            timer: 5000,
            showConfirmButton: false,
            showCancelButton: false,

          });

          this.loding = true;
        }
      }
    });




    setInterval(() => {

      try {


        //this._userService.Fecthstatususer()

        this._userService.CheckRealStatus(localstore.model.memberId)
          .pipe(first())
          .subscribe(

            data => {


              if (data == -1) {
                console.log("Re join");
                this.joinSignalRChatRoom();
              }


            },
            error => {


            });
      }
      catch (e) {
        // this.Logout();

        localStorage.removeItem('currentUserType');
        localStorage.removeItem('currentAdminUser');
        localStorage.removeItem('currentHospitalUser');
        localStorage.removeItem('currentUser');;

        var date = new Date();
        date.setTime(date.getTime() + (1 * 10));
        this.cookieService.set('esanjcookie', '', date, "", "", AppConfig.securecookie);
        this.cookieService.set('esanjhcookie', '', date, "", "", AppConfig.securecookie);
        this.cookieService.set('esanjacookie', '', date, "", "", AppConfig.securecookie);

      }
    }, 9000);



    ///////////////Check Session Hijacking


    ///////////////Check Session Hijacking

    this.userIdle.startWatching();

    this.userIdle.onTimerStart().subscribe(() => this.Logout());


  }



  firstFunction() {

    Swal.close();
  }



  callrouterLink(path) {

    this.storageService.Currenturl = path;

    this.router.navigate([path]);




    for (let i = 0; i < this.menulist.length; i++) {
      if (this.menulist[i].pathUrl == path) {
        this.menulist[i].isActive = true;
      } else {
        this.menulist[i].isActive = false;
      }
    }
  }

  Logout() {


    this._userService.Doctorlogout()
      .pipe(first())
      .subscribe(

        data => {
          this.storageService.SignalRAdapter.CloseChatGrid(this.storageService.otherEndChatId);
          this.storageService.isConnectionEstablished = false;
          this.storageService.isConsultationActive = false;
          this.storageService.SignalRAdapter.LeaveConnference();
          localStorage.removeItem('currentUser');
          localStorage.removeItem('currentUserType');
          localStorage.removeItem('HCubeStatus');
          localStorage.removeItem('MenuStaus');
          window.location.href = "/login";
          //this.router.navigate(['/Doctor/Login']);
        });
  }
  switchTheme(theme: string): void {
    this.currentTheme = theme;
  }

  onEventTriggered(event: string): void {
    this.triggeredEvents.push(event);
  }

  joinSignalRChatRoom(): void {




    //const userName =  prompt('Please enter a user name:');




    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    //Check whether user authorised for chat
    if (currentUser && currentUser.token) {


      const fname = (`${currentUser.model.firstName}` == null) || (`${currentUser.model.firstName}` == "") || (`${currentUser.model.firstName}` == "undefined") ? null : `${currentUser.model.firstName}`;

      const mID = `${currentUser.model.memberId}`;

      //alert(JSON.stringify(userName));

      if (fname != null && mID != null) {


        this.username = `${currentUser.model.prefix}` + " " + `${currentUser.model.firstName}` + " " + `${currentUser.model.middleName}` + " " + `${currentUser.model.lastName}`;


        this.signalRAdapter = new SignalRAdapter(this._userService, this.storageService, this.username, mID, this.http, this.serverBaseUrl, this.router, this.hospitalname, this.eventEmitterService);
        this.storageService.SignalRAdapter = this.signalRAdapter;

        //this.signalRAdapter = new SignalRGroupAdapter(this.username, this.http, this.serverBaseUrl);
      }
      else {
        alert("user details not available");
      }

    }
    else {
      //Check whether user authorised for chat 
      alert("You are not logged in for chat");
    }
  }

  //addition 28th
  onAudioMuteCLick(): void {


    this.AudioUnMute = true;
    this.AudioMute = false;

    this.signalRAdapter.setMute('audio', true);

  }
  onAudioUnMuteCLick(): void {

    this.AudioUnMute = false;
    this.AudioMute = true;
    this.signalRAdapter.setMute('audio', false);


  }
  onVideoMuteCLick(): void {
    this.VideoUnMute = true;
    this.VideoMute = false;
    this.signalRAdapter.setMute('video', true);

  }
  onVideoUnMuteCLick(): void {
    this.VideoUnMute = false;
    this.VideoMute = true;
    this.signalRAdapter.setMute('video', false);

  }
  onEndCallSubmitCLick(): void {

    Swal.fire({
      title: '',
      text: "Are You Sure, You Want To End VC?",
      showCancelButton: true,
      confirmButtonColor: '#133656',
      cancelButtonColor: '#E5C151',
      confirmButtonText: 'Yes',
      cancelButtonText: "No",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false

    }).then((result) => {
      if (result.value) {

        this.storageService.isConnectionEstablished = false;

        this.signalRAdapter.requestEndVC();


        this._userService.UpdateVCResponseTime({ "ConsultationId": this.storageService.consultationId, "FromMemberId": this.storageService.SenderId, "ToMemberId": this.storageService.ReceiverId })
          .pipe(first())
          .subscribe(
            data => {

            })




      }
    })


  }

  onFullScreen(): void {
    var remote: HTMLVideoElement;
    remote = <HTMLVideoElement>document.getElementById('remote');

    if (remote.requestFullscreen) {
      remote.requestFullscreen();
    }
  }


  setLeftMenu() {
    this.isCollapsed = !this.isCollapsed;
    localStorage.setItem('MenuStaus', JSON.stringify(this.isCollapsed));

    if (!this.isCollapsed) {
      this.mainBody = 'body_inner adminbody';
      this.mainClass = 'forced';

    }
    else {
      this.mainBody = 'body_inner adminbody-void';
      this.mainClass = 'forced enlarged';

    }
  }




}
