import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpModule, Http, Headers, Response, URLSearchParams, RequestOptions, RequestMethod } from '@angular/http';

import { WBSiteLayoutComponent } from './components/_layout/wbsite-layout/wbsite-layout.component';

import { SiteLayoutComponent } from './components/_layout/site-layout/site-layout.component';
import { AppLayoutComponent } from './components/_layout/app-layout/app-layout.component';
import { HospitallayoutComponent } from './components/_layout/hospitallayout/hospitallayout.component';
import { DicomviewerComponent } from './components/dicomviewer/dicomviewer.component';
import { DoctorlayoutComponent } from './components/_layout/doctorlayout/doctorlayout.component';
import { PatientlayoutComponent } from './components/_layout/patientlayout/patientlayout.component';
import { CanDeactivateGuard } from './_guards/deactive.guard';
import { PrinthfreportComponent } from './components/printreport/printhfreport/printhfreport.component';
import { PrintproblemreportComponent } from './components/printreport/printproblemreport/printproblemreport.component';
import { PrintconsulationstatusreportComponent } from './components/printreport/printconsulationstatusreport/printconsulationstatusreport.component';
import { PrintconsulationsummaryreportComponent } from './components/printreport/printconsulationsummaryreport/printconsulationsummaryreport.component';
import { PrintdormantreportComponent } from './components/printreport/printdormantreport/printdormantreport.component';
import { AuthGuard } from './_guards';





import { PrintPOSreportComponent } from './components/printreport/printPOS/printPOSreport.component'


const routes: Routes = [

  ////////////////////////////////////////////////////Hospital Admin Route////////////////////////

  {
    path: '',
    component: WBSiteLayoutComponent,
    children: [
      { path: '', loadChildren: 'src/app/components/hospital/login/_module#HAdminLoginModule', pathMatch: 'full' },
      { path: 'Login', loadChildren: 'src/app/components/hospital/login/_module#HAdminLoginModule' },
    ]
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [

      // { path: 'Login', loadChildren: 'src/app/components/hospital/login/_module#HAdminLoginModule' },


      { path: 'LoginOTP', loadChildren: 'src/app/components/hospital/loginotp/_module#HAdminLoginOTPModule' },
      { path: 'ForgotPassword', loadChildren: 'src/app/components/hospital/fgpassword/_module#HAdminfgpwdModule' },
      { path: 'Admin/Login', loadChildren: 'src/app/components/admin/login/_module#AdminLoginModule' },
      { path: 'admin/login', loadChildren: 'src/app/components/admin/login/_module#AdminLoginModule' },
      { path: 'Admin/LoginOTP', loadChildren: 'src/app/components/admin/loginotp/_module#AdminLoginOTPModule' },
      { path: 'Admin/ForgotPassword', loadChildren: 'src/app/components/admin/fgpassword/_module#AdminFgpasswordModule' },

      { path: 'Covid19/Login', loadChildren: 'src/app/components/covid/login/_module#covidLoginModule' },
      { path: 'Covid19/LoginOTP', loadChildren: 'src/app/components/covid/loginotp/_module#covidLoginOTPModule' },
      { path: 'Covid19/Register', loadChildren: 'src/app/components/covid/register/_module#covidRegisterModule' }
    ]
  },

  {
    path: '',
    component: HospitallayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'Hospital/Dashboard', loadChildren: 'src/app/components/hospital/dashboard/_module#HAdminDashboardModule' },
      { path: 'Hospital', loadChildren: 'src/app/components/hospital/doctor/_module#doctorModule' },
      { path: 'Hospital/Setting', loadChildren: 'src/app/components/hospital/setting/_module#HAdminSettingModule' },
    ]
  },
  {
    path: '',
    component: PatientlayoutComponent,
    children: [
      { path: 'Covid19/Dashboard', loadChildren: 'src/app/components/covid/dashboard/_module#covidDashboardModule' },
      { path: 'Covid19/consultation', loadChildren: 'src/app/components/covid/consultation/_module#covidConsultationModule' },

    ]
  },
  {
    path: '',
    component: DoctorlayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'Doctor/Dashboard', loadChildren: 'src/app/components/doctor/dashboard/_module#DAdminDashboardModule' },
      { path: 'Doctor', loadChildren: 'src/app/components/doctor/patient/_module#patientModule' },
      { path: 'Doctor/Setting', loadChildren: 'src/app/components/doctor/setting/_module#DAdminSettingModule' },
      { path: 'Doctor/Specialty', loadChildren: 'src/app/components/doctor/specialty/_module#specialtyModule' },
      { path: 'Doctor/Remote', loadChildren: 'src/app/components/doctor/remote/_module#remoteModule' },
      {
        path: 'Doctor/Consulatationdetail',
        loadChildren: 'src/app/components/doctor/consulatationdetail/_module#consulationdetailModule',
        canDeactivate: [CanDeactivateGuard],
        data: {
          role: 'Speciality'
        }
      },
      {
        path: 'Doctor/Inprocessconsultation',
        loadChildren: 'src/app/components/doctor/inprocessconsultation/_module#inprocessconsultationModule',
        canDeactivate: [CanDeactivateGuard],
        data: {
          role: 'Speciality'
        }
      },
      {
        path: 'Doctor/prescription/:id',
        loadChildren: 'src/app/components/doctor/prescription/_module#prescriptionModule',
        canDeactivate: [CanDeactivateGuard],
        data: {
          role: 'Remote'
        }
      },
      {
        path: 'Doctor/Caserefferedsummary/:id',
        loadChildren: 'src/app/components/doctor/caserefferedsummary/_module#caserefferedsummaryModule',
        canDeactivate: [CanDeactivateGuard],
        data: {
          role: 'Remote'
        }
      },
      { path: 'Doctor/Ncd', loadChildren: 'src/app/components/doctor/ncdpatient/_module#ncdpatientModule' }
    ]
  }
  ,
  {
    path: '',
    component: AppLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'Admin', loadChildren: 'src/app/components/admin/hospital/_module#hospitalModule' },
      { path: 'Admin/Report', loadChildren: 'src/app/components/report/_module#AdminReportModule' },
      { path: 'Admin/Setting', loadChildren: 'src/app/components/admin/setting/_module#AdminSettingModule' },
      { path: 'Admin/TestDashboard', loadChildren: 'src/app/components/admin/dashboard/_module#AdminDashboardModule' },
      { path: 'Admin/Dashboard', loadChildren: 'src/app/components/admin/testdashboard/_module#TestAdminDashboardModule' },
      { path: 'Admin/Member', loadChildren: 'src/app/components/admin/member/_module#MemberModule' },
      { path: 'Admin/WishDashboard', loadChildren: 'src/app/components/admin/wisedashboard/_module#WishDashboardModule' },
      { path: 'Admin/NcdReport', loadChildren: 'src/app/components/ncdreport/_module#AdminNcdReportModule' },
    ]
  }
  ,
  {
    path: 'Kerala',
    loadChildren: 'src/app/components/hospital/keralalogin/_module#KeralaAdminLoginModule'
  },
  {
    path: 'kerala',
    loadChildren: 'src/app/components/hospital/keralalogin/_module#KeralaAdminLoginModule'
  }
  ,
  {
    path: 'Statistics',
    loadChildren: 'src/app/components/nhmdashboard/_module#NhmdashboardpageModule'

  }, {
    path: 'chi',
    loadChildren: 'src/app/components/nhmdashboard/_module#NhmdashboardpageModule'

  },
  {
    path: 'ErrorPage',
    loadChildren: 'src/app/components/errorpage/_module#ErrorpageModule'

  },
  {
    path: 'Error',
    loadChildren: 'src/app/components/errorpage/_module#ErrorpageModule'

  },
  {
    path: 'error',
    loadChildren: 'src/app/components/errorpage/_module#ErrorpageModule'

  },
  { path: 'PrintHealthFacility', component: PrinthfreportComponent, canActivate: [AuthGuard] },
  { path: 'PrintProblem', component: PrintproblemreportComponent, canActivate: [AuthGuard] },
  { path: 'Printconsulationstatus', component: PrintconsulationstatusreportComponent, canActivate: [AuthGuard] },
  { path: 'Printconsulationsummary', component: PrintconsulationsummaryreportComponent, canActivate: [AuthGuard] },
  { path: 'Printdormantsummary', component: PrintdormantreportComponent, canActivate: [AuthGuard] },

  { path: 'PrintPOSPrescription', component: PrintPOSreportComponent, canActivate: [AuthGuard] },


  { path: 'Doctor/dicomviewer', component: DicomviewerComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' },

];

@NgModule({
  imports: [
    RouterModule.forChild(routes),

  ],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {
}
