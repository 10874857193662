import { Component, OnInit } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ExcelService } from '../../../_services/excel.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-printPOSreport.component',
  templateUrl: './printPOSreport.component.html'
})
export class PrintPOSreportComponent implements OnInit {



  feacthchdata: any;
  searchdatalist: any;
  casedata: any;
  AdviceNote: any;
  AdviceNoteshow: boolean = false;
  currentdate: Date = new Date();
  Weight: string = "NA";
  BloodPressure: string = "NA";
  PulseRate: string = "NA";
  Temperature: string = "NA";
  BloodGlucose: string = "NA";
  BloodGroup: string = "NA";
  ageyear: number = 0;
  agemonth: number = 0;

  ageyearstatus: boolean = false;
  agemonthstatus: boolean = false;
  printedhow: boolean = true;
  Note: any = [];


  constructor(private excelService: ExcelService) { }




  ngOnInit() {

    this.feacthchdata = window;
    this.searchdatalist = this.feacthchdata.searchdata


    if (this.searchdatalist == null || this.searchdatalist == undefined || this.searchdatalist == "") {
      window.close();
    }


    var agedata = this.feacthchdata.searchdata.patientConsultationModel.patientAge;
    if (agedata == 0) {

      this.ageyear = 0;
      this.agemonth = 0;
      if (this.ageyear == 0) {
        this.ageyearstatus = false;
      }
      if (this.agemonth == 0) {
        this.agemonthstatus = false;
      }

    } else {

      this.ageyear = Math.floor(agedata / 12);
      this.agemonth = parseInt(agedata) % 12;
      if (this.ageyear == 0) {
        this.ageyearstatus = true;
      }
      if (this.agemonth == 0) {
        this.agemonthstatus = true;
      }

    }

    this.AdviceNote = this.searchdatalist.lstConsultationMessageModel.filter(x => x.messageTypeId == 2);
    if (this.AdviceNote.length > 0) {
      this.AdviceNoteshow = true;
    } else {
      this.AdviceNoteshow = false;
    }


    if (this.searchdatalist.lstConsultationTestResultsModel != null && this.searchdatalist.lstConsultationTestResultsModel.length > 0) {




      if (this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 4) != "") {
        this.Weight = this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 4)[0].result;;
      };




      if (this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 2) != "") {
        this.BloodPressure = this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 2)[0].result;
      };


      if (this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 7) != "") {
        this.PulseRate = this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 7)[0].result;
      };


      if (this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 1) != "") {
        this.Temperature = this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 1)[0].result;
      };


      if (this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 8) != "") {
        this.BloodGlucose = this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 8)[0].result;
      };


      if (this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 13) != "") {
        this.BloodGroup = this.searchdatalist.lstConsultationTestResultsModel.filter(x => x.testId == 13)[0].result;
      };

    }


    if (this.searchdatalist.lstConsultationMedicineModel != null && this.searchdatalist.lstConsultationMedicineModel.length > 0) {

      for (var i = 0; i < this.searchdatalist.lstConsultationMedicineModel.length; i++) {
        if (this.searchdatalist.lstConsultationMedicineModel[i].medComments != "") {
          this.Note.push({
            "medComments": this.searchdatalist.lstConsultationMedicineModel[i].medComments, "medCommentsno": i + 1
          });
        }
      }

    }

    this.printDiv();
  }










  printDiv() {

    window.print();
    return false;


  }

}
