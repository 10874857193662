
import { Component } from '@angular/core';
import { StorageService } from './_services/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  constructor(
    public storageService: StorageService
  ) {



  }



}
