import { ChatAdapter, IChatGroupAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from '../../ng-chat';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as signalR from "@aspnet/signalr";
import { StorageService } from '../../_services/index';
import { AppConfig } from '../../_helpers/config';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import 'sweetalert2/src/sweetalert2.scss';
//import { ConsulatationdetailComponent } from '../doctor';
//import { Input } from '@angular/core';
import { DUserService } from '../../_services/doctor/index';
import { first, catchError, map } from 'rxjs/operators';
import { MessageType } from '../../ng-chat/core/message-type.enum';
declare var SignalingChannel: any;
//declare var Ics: any; // june-06-2019
import { EventEmitterService } from '../../_services/event-emitter.service';
import { Console } from '@angular/core/src/console';
declare var Owt: any;
export class SignalRAdapter extends ChatAdapter {

  /**********/
  //PPL
  public remoteUser: string; //addition 28th
  public signaling = new SignalingChannel();
  public serverAddress: string = 'https://awspeer2.instavc.com';

  //public serverAddress: string = 'https://cdacpeer.golive.ai';
  public publicationForCamera;
  public isP2PConnected: Boolean = false;
  public localStream;
  public earlyJoin;
  public p2p = null;
  public publishedA = false; // june-06-2019




  //Commented now
  //public p2p = new Ics.P2P.P2PClient({
  //  audioEncodings: true,
  //  videoEncodings: [{ codec: { name: 'h264' } }, { codec: { name: 'vp9' } }, { codec: { name: 'vp8' } }],
  //  rtcConfiguration: {
  //    iceServers: [{
  //      urls: "stun:120.25.230.17:19302"
  //    }, {
  //      urls: ["turn:5.187.21.5:443?transport=udp",
  //        "turn:5.187.21.7:443?transport=tcp"
  //      ],
  //      credential: "admin123",
  //      username: "admin"
  //    }]
  //  },
  //}, this.signaling);

  //PPL END

  /**********/

  public userId: string;

  //public hubConnection: signalR.HubConnection
  //public static serverBaseUrl: string = 'http://localhost:54412/'; //''; // Set this to 'https://localhost:5001/' if running locally
  //@Input() oConsulatationdetailComponent: ConsulatationdetailComponent;
  public serverBaseUrl: string;

  public memberId: string;
  public hospitalname: string;
  constructor(private UserService: DUserService, private storageService: StorageService, private username: string, private _memberId: string, private http: HttpClient, private _serverBaseUrl: string, private router: Router, private _hospitalname: string, private eventEmitterService: EventEmitterService) {

    super();


    this.memberId = _memberId;
    this.hospitalname = _hospitalname;
    this.serverBaseUrl = _serverBaseUrl;

    this.initializeConnection();
  }

  private initializeConnection(): void {
    //this.hubConnection = new signalR.HubConnectionBuilder()
    //  .withUrl(`${SignalRAdapter.serverBaseUrl}chat`)
    //  .build();



    this.storageService.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.serverBaseUrl}chat`)
      //.configureLogging(signalR.LogLevel.Trace)
      .configureLogging({
        log: function (logLevel, message) {
          // console.log(new Date().toISOString() + ": " + " Log Level : " + logLevel + " Message : " + message);
        }
      })
      .build();
    //Specify TimeOUT
    this.storageService.hubConnection.serverTimeoutInMilliseconds = 30000; //1000 * 60 * 10; // 1 second * 60 * 10 = 10 minutes.



    this.storageService.hubConnection
      .start()
      .then(() => {
        this.joinRoom();

        this.initializeListeners();
      })
      .catch(err => console.log(`Error while starting SignalR connection: ${err}`));
    // localStorage.setItem('adapter', JSON.stringify(this.hubConnection));
    // re-establish the connection if connection dropped

    // this.storageService.hubConnection.onclose(() => setTimeout(startSignalRConnection(this.storageService.hubConnection), 5000));

    // const startSignalRConnection = connection => connection.start()
    //   .then(() => {
    //     this.joinRoom();

    //     this.initializeListeners();
    //   })
    //   .catch(err => console.log(`Error while reconnecting SignalR connection: ${err}`));





  }




  private initializeListeners(): void {
    this.storageService.hubConnection.on("generatedUserId", (connectionid, memberId) => {
      //alert("ConnectionID:" + connectionid + " Memberid:" + memberId);
      // With the userId set the chat will be rendered
      console.log('Signaling', this.signaling);
      this.userId = connectionid;
    });

    this.storageService.hubConnection.on("messageReceived", (participant, message) => {
      this.storageService.otherEndChatId = message.fromId;

      // Handle the received message to ng-chat
      console.log("OnMessageReceived:", this.userId, message);

      if (message.type === MessageType.VCCommand && message.message === "VideoCallRequested") {
        console.log('Share while VidecALLrEQUESTED', this.userId, message.fromId);
        this.initP2PConnection(this.userId, message.fromId, false);
      } else if ((message.type === MessageType.VCCommand && message.message === "VideoCallConnected")) { //VideoCallConnected
        //this.publishedA = true;  // june-06-2019
        //this.shareCamera(message.fromId);  // june-06-2019
        console.log('Share while VideoCallConnected', message.fromId);
      } else if ((message.type === MessageType.VCCommand && message.message === "EndCallRequested")) {
        this.LeaveConnference();
        this.clientSwalClose(message);

      } else if (message.type === MessageType.VCCommand && message.message === "VideoCallReConnectRequest") {
        if (!this.isP2PConnected) {
          this.initP2PConnection(this.userId, message.fromId, true);
        }
      }


      this.onMessageReceived(participant, message);


    });
    this.storageService.hubConnection.on("vcReceived", (participant, message) => {
      //alert("VC Call Received");

      //Call for VC Allow Camera Functionalities.
      this.sendMessage(message);

      //if (message.fromID != this.userId)
      //  this.callBackVCSendMessage(message);

    });
    this.storageService.hubConnection.on("friendsListChanged", (participantsResponse: Array<ParticipantResponse>) => {
      // Handle the received response to ng-chat
      this.onFriendsListChanged(participantsResponse.filter(x => x.participant.id != this.userId));
    });


    this.storageService.hubConnection.on("objectReceived", (participant, message) => {
      if (this.storageService.consultationId == message.consultationId) {
        this.storageService.hubmessage = message;
        //this.storageService.consultationId = message.consultationId;
        this.storageService.Issync = true;
        this.fetchdata();
      }
      //alert(message.consultationId);
      // this.oConsulatationdetailComponent.checkobject();
      //
      // this.router.navigate(['/Doctor/Consulatationdetail']);
    });
    this.storageService.hubConnection.on("RedirectToRefferredGrid", (participant, message) => {
      //alert("RedirectToReceivedGrid Paricipantdata : " + JSON.stringify(participant) + " Message Data : " + JSON.stringify(message));
      //this.storageService.hubConnection.stop();
      this.storageService.isConnectionEstablished = false;
      this.storageService.isConsultationActive = false;
      //   this.requestEndVC();

      //Receiver Close
      //  this.onGridChatClosed(message.fromId);

      //Close Call Back vc and chat
      // this.gridCloseCallBackControl();

      this.onGridChatClosed(message.fromId);
      this.router.navigate(['/Doctor/Casereffered']);
    });

    this.storageService.hubConnection.on("RedirectToReceivedGrid", (participant, message) => {

      this.storageService.isConnectionEstablished = false;
      this.storageService.isConsultationActive = false;
      this.onGridChatClosed(message.fromId);

      this.router.navigate(['/Doctor/Casereceived']);
    });


    this.storageService.hubConnection.on("AcceptCall", (participant, message) => {

      Swal.fire({
        title: '',
        text: 'Call Accepted',
        type: 'success',
        confirmButtonText: 'Ok',
        timer: 1000,
        showConfirmButton: false,
        showCancelButton: false,
      });
    });


    this.storageService.hubConnection.on("DenyCall", (participant, message) => {


      Swal.fire({
        title: '',
        text: 'Call Denied',
        type: 'success',
        confirmButtonText: 'Ok',
        timer: 4000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      this.storageService.isConnectionEstablished = false;
      this.storageService.isConsultationActive = false;
      this.storageService.btnsavedraftStatus = false;
    });


    this.storageService.hubConnection.on("RemoteLeaveCall", (participant, message) => {

      this.storageService.SenderId = 0;
      this.storageService.ReceiverId = 0;
      Swal.fire({
        title: '',
        text: 'Consultation ended by other side ',
        type: 'success',
        confirmButtonText: 'Ok',
        timer: 1000,
        showConfirmButton: false,
        showCancelButton: false,
      });

      this.storageService.isConnectionEstablished = false;
      this.storageService.isConsultationActive = false;
      window.location.href = '/Doctor/Specialty/Casereceived';
    });


    this.storageService.hubConnection.on("SpecialistLeaveCall", (participant, message) => {

      this.storageService.SenderId = 0;
      this.storageService.ReceiverId = 0;
      Swal.fire({
        title: '',
        text: 'Consultation ended by other side ',
        type: 'success',
        confirmButtonText: 'Ok',
        timer: 1000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      this.storageService.isConnectionEstablished = false;
      this.storageService.isConsultationActive = false;
      window.location.href = '/Doctor/Remote/Casereffered';

    });



    this.storageService.hubConnection.on("fallbackcall", (participant, message) => {

      this.storageService.SenderId = 0;
      this.storageService.ReceiverId = 0;
      Swal.fire({
        title: '',
        text: 'User is busy in another session',
        type: 'success',
        confirmButtonText: 'Ok',
        timer: 1000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      this.storageService.isConnectionEstablished = false;
      this.storageService.isConsultationActive = false;
      this.storageService.ddlshow = false;
      this.storageService.btnsavedraftStatus = false;
    });


    this.storageService.hubConnection.on("successcall", (participant, message) => {


      Swal.fire({
        title: '',
        text: 'Call Initiating',
        type: 'success',
        confirmButtonText: 'Ok',
        timer: 1000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      this.storageService.isConnectionEstablished = true;
      this.storageService.isConsultationActive = true;
      this.storageService.ddlshow = true;
    });



    this.storageService.hubConnection.on("Ping", (participant, message) => {


      let msg = new Message();
      msg.fromId = message.toId;
      msg.toId = message.fromId;

      this.storageService.hubConnection.send("PingResponse", msg);
    });




    this.storageService.hubConnection.on("PingResponse", (participant, message) => {

      this.storageService.singnalrstatus = true;
    });



    this.storageService.hubConnection.on("successrecall", (participant, message) => {


      Swal.fire({
        title: '',
        text: 'Call Re-initiating ',
        type: 'success',
        confirmButtonText: 'Ok',
        timer: 1000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      this.storageService.isConnectionEstablished = true;
      this.storageService.isConsultationActive = true;
      this.storageService.ddlshow = true;
    });



    this.storageService.hubConnection.on("consultationReceived", (participant, message) => {

      console.log(this.storageService.showpop);
      if (this.storageService.showpop == false) {

        this.storageService.showpop = true;
        this.RingSound(true);
        //console.log(this.storageService.globalprescription);
        Swal.fire({
          title: 'Consultation Request',
          text: "From " + participant.displayName.replace('Mr.', '') + ", " + participant.fromHospitalName,
          showCancelButton: true,
          confirmButtonColor: '#133656',
          cancelButtonColor: '#E5C151',
          confirmButtonText: 'Accept',
          cancelButtonText: "Deny",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false

        }).then((result) => {



          

          if (result.value) {

            this.RingSound(false);

            this.UserService.CheckConsultationCurrentStatus(message.consultationId)
              .pipe(first())
              .subscribe(

                data => {

                  if (data.model != null) {

                    this.storageService.SenderId = message.toId;
                    this.storageService.ReceiverId = message.fromId;

                    //alert(this.userId +"  aaa gya")
                    console.log("CurrentUser: ", this.userId);

                    console.log("Reciever: ", JSON.stringify(participant));

                    console.log("Message: ", JSON.stringify(message));

                    this.storageService.hubmessage = message;
                    this.storageService.userId = this.userId;
                    this.storageService.globalprescription = message.consultationId;
                    this.storageService.consultationId = message.consultationId;
                    this.storageService.isConnectionEstablished = true;
                    this.storageService.isConsultationActive = false;
                    this.storageService.isLoaderShow = true;
                    this.storageService.FromHospitalName = participant.fromHospitalName;
                    this.storageService.Currenturl = "/Doctor/Consulatationdetail";


                    this.eventEmitterService.onFirstComponentButtonClick();

                    this.storageService.showpop = false;

                    var resquestjson = {
                      "consultationId": message.consultationId,
                      "requestFrom": this.storageService.hubmessage.fromId,
                      "requestTo": this.storageService.hubmessage.toId,

                    }


                    this.UserService.UpdateConsultationStatus(resquestjson)
                      .pipe(first())
                      .subscribe(

                        data => {


                          if (data.success) {
                            this.router.navigate(['/Doctor/Consulatationdetail'], { queryParams: { id: message.consultationId } });

                          } else {

                            error => {
                              Swal.fire({
                                type: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong',
                                confirmButtonText: 'Ok',
                                showCancelButton: false,
                              })


                            }
                          }
                        });

                  } else {



                    Swal.fire({
                      title: '',
                      text: 'Case discarded from other side',
                      type: 'success',
                      confirmButtonText: 'Ok',
                      timer: 1000,
                      showConfirmButton: false,
                      showCancelButton: false,
                    })


                  }


                })


          } else {


            let messagecall = new Message();
            messagecall.fromId = message.toId;
            messagecall.toId = message.fromId;
            this.storageService.showpop = false;

            this.storageService.hubConnection.send("DenyCall", messagecall);


            this.RingSound(false);


          }
        })

      }

    });




    this.storageService.hubConnection.on("consultationRecall", (participant, message) => {

      console.log(this.storageService.showpop);

      if (this.storageService.showpop == false) {

        this.storageService.showpop = true;
        //console.log(this.storageService.globalprescription);
        Swal.fire({
          title: 'Consultation Request',
          text: "From " + participant.displayName.replace('Mr.', '') + ", " + participant.fromHospitalName,
          showCancelButton: true,
          confirmButtonColor: '#133656',
          cancelButtonColor: '#E5C151',
          confirmButtonText: 'Accept',
          cancelButtonText: "Deny",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false

        }).then((result) => {
          if (result.value) {


            this.UserService.CheckConsultationCurrentStatus(message.consultationId)
              .pipe(first())
              .subscribe(

                data => {

                  if (data.model != null) {

                    //alert(this.userId +"  aaa gya")
                    console.log("CurrentUser: ", this.userId);

                    console.log("Reciever: ", JSON.stringify(participant));

                    console.log("Message: ", JSON.stringify(message));

                    this.storageService.SenderId = message.toId;
                    this.storageService.ReceiverId = message.fromId;

                    this.storageService.hubmessage = message;
                    this.storageService.userId = this.userId;
                    this.storageService.globalprescription = message.consultationId;
                    this.storageService.consultationId = message.consultationId;

                    this.storageService.isConnectionEstablished = true;
                    this.storageService.isConsultationActive = true;
                    this.storageService.isLoaderShow = true;

                    this.storageService.FromHospitalName = participant.fromHospitalName;

                    this.storageService.Currenturl = window.location.href;


                    if (this.storageService.Currenturl.includes("Consulatationdetail")) {

                      if (!this.storageService.SignalRAdapter.isP2PConnected) {


                        this.storageService.SignalRAdapter.initP2PConnection(this.storageService.userId, this.storageService.hubmessage.connectionId, true);
                        this.storageService.isConnectionEstablished = true;
                        this.storageService.isConsultationActive = true;

                      }
                      ////Open Received user message Window

                      //if (this.storageService.hubmessage.fromID != this.storageService.userId)
                      // this.storageService.SignalRAdapter.callBackSendMessage(this.storageService.hubmessage);

                      //////Open Sender Window Send back Message
                      ////Only Called When Consulatation is accepted by Receiver



                      var resquestjson = {
                        "consultationId": message.consultationId,
                        "requestFrom": this.storageService.hubmessage.fromId,
                        "requestTo": this.storageService.hubmessage.toId,

                      }



                      this.UserService.UpdateConsultationStatus(resquestjson)
                        .pipe(first())
                        .subscribe(

                          data => {

                          });



                      this.UserService.GetConsultationByID(message.consultationId)
                        .pipe(first())
                        .subscribe(

                          data => {

                            if (data.success) {


                              if (data.model != null && data.model.patientConsultationModel != null) {
                                this.storageService.globalprescription = data.model;
                                this.storageService.isConnectionEstablished = true;
                                this.storageService.isConsultationActive = true;
                              }
                            } else {
                              this.storageService.globalprescription = [];

                            }

                          },
                          error => {
                            this.storageService.globalprescription = [];


                          });


                    } else {


                      var resquestjson = {
                        "consultationId": message.consultationId,
                        "requestFrom": message.fromId,
                        "requestTo": message.toId,

                      }

                      this.UserService.UpdateConsultationStatus(resquestjson)
                        .pipe(first())
                        .subscribe(

                          data => {

                          });



                      this.router.navigate(['/Doctor/Consulatationdetail'], { queryParams: { id: message.consultationId } });
                    }

                  } else {


                    Swal.fire({
                      title: '',
                      text: 'Case discarded from other side',
                      type: 'success',
                      confirmButtonText: 'Ok',
                      timer: 1000,
                      showConfirmButton: false,
                      showCancelButton: false,
                    })



                  }
                })

          }


          else {

            this.storageService.showpop = false;
            let messagecall = new Message();
            messagecall.fromId = message.toId;
            messagecall.toId = message.fromId;


            this.storageService.hubConnection.send("DenyCall", messagecall);

          }

        })


      }
    });


  }

  initP2PConnection(selfId, toId, earlyJoin = true): void {
    /**Addition*****/

    if (!this.signaling) {
      console.log('Sinalling Created');
      this.signaling = new SignalingChannel();
    }

    if (!this.p2p) {
      // june-06-2019
      /*   
      this.p2p = new Owt.P2P.P2PClient({
        audioEncodings: true,
        videoEncodings: [{ codec: { name: 'h264' } }, { codec: { name: 'vp9' } }, { codec: { name: 'vp8' } }],
        rtcConfiguration: {
          iceServers: [{
            urls: "stun:120.25.230.17:19302"
          }, {
            urls: ["turn:5.187.21.5:443?transport=udp",
              "turn:5.187.21.7:443?transport=tcp"
            ],
            credential: "admin123",
            username: "admin"
          }]
        },
      }, this.signaling);
      */

      this.p2p = new Owt.P2P.P2PClient({
        audioEncodings: true,
        videoEncodings: [{ codec: { name: 'h264' } }, { codec: { name: 'vp9' } }, { codec: { name: 'vp8' } }],
        rtcConfiguration: {
          iceServers: [{
            urls: "stun:139.59.12.194:19302"
          }, {
            urls: ["turn:coturn.instavc.com:443?transport=udp",
              "turns:coturn.instavc.com:443?transport=tcp"
            ],
            credential: "admin123",
            username: "admin"
          }]
        },
      }, this.signaling);

    }

    this.p2p.addEventListener('serverdisconnected', (e) => {
      console.log('Server Disconnected');
    });

    this.p2p.addEventListener('streamadded', (e) => { // A remote stream is available.

      /*if (e.stream.source.video === 'screen-cast') {
        $('#screen video').show();
        $('#screen video').get(0).srcObject = e.stream.mediaStream;
        $('#screen video').get(0).play();
      } else */
      console.log('Adding Remote Stream');
      if (e.stream.source.audio || e.stream.source.video) {
        var remote: HTMLVideoElement;
        remote = <HTMLVideoElement>document.getElementById('remote');
        remote.srcObject = e.stream.mediaStream;
        remote.play();

        e.stream.addEventListener('ended', () => {
          console.log('Stream is removed.');
          remote.srcObject = null;
          //this.isP2PConnected = false;
        });
        // june-06-2019
        if (this.earlyJoin) {
          this.shareCamera(toId);
        }
      }
    });

    this.p2p.connect({
      host: this.serverAddress,
      token: this.userId
    }).then((res) => {
      console.log('P2P Connected', this.p2p);
      this.remoteUser = toId;
      console.log("Remote ID Allowed", toId);
      this.p2p.allowedRemoteIds = [toId];
      this.isP2PConnected = true;
      this.earlyJoin = earlyJoin;
      if (earlyJoin) {
        console.log('Early join - so no camera');
        this.requestVC(selfId, toId);
      } else {
        console.log('Late Join share camera');
        this.shareCamera(toId);
        //this.callBackVCSendMessage()
      }

    });
  }

  shareCamera(strTo): void {
    if (this.localStream) {
      this.p2p.publish(strTo, this.localStream).then(publication => {
        this.publicationForCamera = publication;
        console.log('Publication', this.publicationForCamera); //addition 28th
      }); // Publish local stream to remote client
    } else {
      const audioConstraintsForMic = new Owt.Base.AudioTrackConstraints(Owt.Base.AudioSourceInfo.MIC);
      const videoConstraintsForCamera = new Owt.Base.VideoTrackConstraints(Owt.Base.VideoSourceInfo.CAMERA);
      let mediaStream;
      Owt.Base.MediaStreamFactory.createMediaStream(new Owt.Base.StreamConstraints(audioConstraintsForMic, videoConstraintsForCamera)).then(stream => {
        mediaStream = stream;
        this.localStream = new Owt.Base.LocalStream(mediaStream, new Owt.Base.StreamSourceInfo('mic', 'camera'));
        var local: HTMLVideoElement;
        local = <HTMLVideoElement>document.getElementById('local');
        local.srcObject = this.localStream.mediaStream; //URL.createObjectURL(stream);
        local.muted = true;
        this.p2p.publish(strTo, this.localStream).then(publication => {
          console.log('Video Published to ', strTo, publication);
          this.publicationForCamera = publication;

          if (!this.earlyJoin) {
            let msgcallback = new Message();
            msgcallback.type = MessageType.VCCommand;
            msgcallback.fromId = this.userId;
            msgcallback.toId = strTo;
            msgcallback.message = "VideoCallConnected";
            msgcallback.dateSent = new Date();

            //alert(JSON.stringify(msgcallback));

            this.sendMessage(msgcallback);
          }
        });
      }, err => {
        console.error('Failed to create MediaStream, ' + err);
      });
    }
  }


  /*******/

  requestEndVC(): void {
    let msgvc = new Message();
    msgvc.type = MessageType.VCCommand;
    msgvc.fromId = this.userId;
    msgvc.toId = this.remoteUser;
    msgvc.message = "EndCallRequested";
    msgvc.dateSent = new Date();
    this.sendMessage(msgvc);
    this.LeaveConnference();
  }

  /*******/

  requestVC(fromId: string, toID: string): void {
    // alert("i am requestVC");
    let msgvc = new Message();
    msgvc.type = MessageType.VCCommand;
    msgvc.fromId = fromId;
    msgvc.toId = toID;
    msgvc.message = "VideoCallRequested";
    msgvc.dateSent = new Date();

    this.vcCall(msgvc);
  }

  vcCall(message: Message): void {
    //Use this to initialize sender camera
    //  alert("VC Call");
    console.log("vccall message: ", this.userId, message);

    if (this.storageService.hubConnection && this.storageService.hubConnection.state == signalR.HubConnectionState.Connected) {
      this.storageService.hubConnection.send("vcCall", message);
      if (message.type === MessageType.VCCommand) {
        //this.shareCamera(message.toId);
      }
    }
  }

  /***/

  setMute = (resource, muteStatus) => {
    if (muteStatus && resource === 'audio') { //Mute
      for (const track of this.localStream.mediaStream.getTracks()) {
        if (track.kind === resource)
          track.enabled = false;
      }
    } else if (resource === 'audio') { //Un Mute
      for (const track of this.localStream.mediaStream.getTracks()) {
        if (track.kind === resource)
          track.enabled = true;
      }
    } else if (muteStatus && resource === 'video') { //Mute
      for (const track of this.localStream.mediaStream.getTracks()) {
        if (track.kind === resource)
          track.enabled = false;
      }
    } else if (resource === 'video') { //Mute
      for (const track of this.localStream.mediaStream.getTracks()) {
        if (track.kind === resource)
          track.enabled = true;
      }
    }
  }

  LeaveConnference = async () => {
    console.log('Disconnecting Server...');
    try {
      if (this.isP2PConnected) {
        this.publicationForCamera.stop();
        for (const track of this.localStream.mediaStream.getTracks()) {
          track.stop();
        };
        this.localStream = null;
        await this.p2p.disconnect();
        this.p2p.removeEventListener('serverdisconnected', () => { });
        this.p2p.removeEventListener('streamadded', () => { });
        //delete this.p2p;
        //delete this.signaling;
        this.isP2PConnected = false;
      }
    }
    catch (error) {
      this.localStream = null;
    }
  }


  /****/

  joinRoom(): void {


    if (this.storageService.hubConnection && this.storageService.hubConnection.state == signalR.HubConnectionState.Connected) {
      this.storageService.hubConnection.send("join", this.username, this.memberId, this.hospitalname);
    }
  }

  listFriends(): Observable<ParticipantResponse[]> {

    // List connected users to show in the friends list
    // Sending the userId from the request body as this is just a demo


    //.post(`${this.serverBaseUrl}listFriends`

    //To be used for live users list of eSanjeevani, Should be filtered basis on TimeSchedule.
    let currentUser = this.storageService.get("currentUser");

    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + currentUser['token']
    });

    let headerOptions = { headers: headers };

    return this.http
      .post(`${this.serverBaseUrl + "api/Home/"}listFriends`, { currentUserId: this.userId }, headerOptions)
      .pipe(
        map((res: any) => res),
        catchError((error: any) => Observable.throw(error.error || 'Server error'))
      );

  }

  getMessageHistory(destinataryId: any): Observable<Message[]> {
    // This could be an API call to your web application that would go to the database
    // and retrieve a N amount of history messages between the users.
    return of([]);
  }

  sendMessage(message: Message): void {
    if (this.storageService.hubConnection && this.storageService.hubConnection.state == signalR.HubConnectionState.Connected)
      this.storageService.hubConnection.send("sendMessage", message);
  }




  StartConsultationMessage(message: Message): void {
    if (this.storageService.hubConnection && this.storageService.hubConnection.state == signalR.HubConnectionState.Connected)
      this.storageService.hubConnection.send("StartConsultationMessage", message);
  }
  //Only Called When Consulatation is accepted by Receiver
  callBackSendMessage(message: Message): void {
    let msgcallback = new Message();

    msgcallback.fromId = message.toId;
    msgcallback.toId = message.fromId;
    msgcallback.message = "CallConnected";
    msgcallback.dateSent = new Date();



    this.StartConsultationMessage(msgcallback);
  }

  consultationCall(message: Message): void {

    if (this.storageService.hubConnection && this.storageService.hubConnection.state == signalR.HubConnectionState.Connected)
      this.storageService.hubConnection.send("consultationCall", message);
  }


  consultationReCall(message: Message): void {

    if (this.storageService.hubConnection && this.storageService.hubConnection.state == signalR.HubConnectionState.Connected)
      this.storageService.hubConnection.send("consultationReCall", message);
  }

  fetchdata() {
    this.storageService.Issync = false;

    this.UserService.GetConsultationByID(this.storageService.consultationId)
      .pipe(first())
      .subscribe(

        data => {

          if (data.success) {

            if (data.model != null && data.model.patientConsultationModel != null) {
              this.storageService.globalprescription = data.model;



              //   this.router.navigate(['/Doctor/Consulatationdetail']);
            }
          } else {
            this.storageService.globalprescription = [];

          }

        },
        error => {
          this.storageService.globalprescription = [];

        });
  }



  callBackVCSendMessage(message: Message): void {
    // alert("VC CALL BACK");

    let msgcallback = new Message();
    msgcallback.type = MessageType.VCCommand;
    msgcallback.fromId = message.toId;
    msgcallback.toId = message.fromId;
    msgcallback.message = "VideoCallConnected";
    msgcallback.dateSent = new Date();

    //alert(JSON.stringify(msgcallback));

    this.sendMessage(msgcallback);
  }

  //Close Chat from Grid
  gridCloseCallBackControl(): void {
    //Callback
    let msgvc = new Message();
    msgvc.type = MessageType.VCCommand;
    msgvc.fromId = this.userId;
    msgvc.toId = this.remoteUser;
    msgvc.message = "EndGridVCCHAT";
    msgvc.dateSent = new Date();
    this.sendMessage(msgvc);
    // this.LeaveConnference();

  }





  clientSwalClose(message: Message): void {
    //console.log("ClientSwal::"+JSON.stringify(message));

    //' + this.storageService.FromHospitalName

    Swal.fire({
      title: '',
      text: 'VC Closed by other side',
      type: 'success',
      confirmButtonText: 'Ok',
      timer: 1000,
      showConfirmButton: false,
      showCancelButton: false,
    });

    this.storageService.isConnectionEstablished = false;

  }



  
  RingSound(audioRingEnabled: boolean): void {
    var audioRingSource: string; //'https://raw.githubusercontent.com/rpaschoal/ng-chat/master/src/ng-chat/assets/notification.wav';
    audioRingSource = '../../../assets/ringer.mp3';
    var audio: HTMLAudioElement;
    audio = <HTMLAudioElement>document.getElementById('ringeraudio');
    audio.src = audioRingSource;
    //audio.muted = true;
    console.log("SoundCalled");

    if (audioRingEnabled) {
      console.log("SoundPlayed");

      audio.load();
      audio.play();

    }
    else {
      console.log("SoundPaused");

      audio.pause();
    }
  }
}






