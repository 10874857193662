import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { VERSION } from '@angular/core';
import * as dwv from 'dwv';
import { MatDialog } from '@angular/material';
import { TagsDialogComponent } from './tags-dialog.component';
import { isNullOrUndefined } from 'util';

// gui overrides

// decode query
dwv.utils.decodeQuery = dwv.utils.base.decodeQuery;
// progress
dwv.gui.displayProgress = function () { };
// get element
dwv.gui.getElement = dwv.gui.base.getElement;
// refresh element
dwv.gui.refreshElement = dwv.gui.base.refreshElement;

// check browser support
dwv.browser.check();
// initialise i18n
//dwv.i18nInitialise("auto", "node_modules/dwv");

// Image decoders (for web workers)
dwv.image.decoderScripts = {
  'jpeg2000': 'assets/dwv/decoders/pdfjs/decode-jpeg2000.js',
  'jpeg-lossless': 'assets/dwv/decoders/rii-mango/decode-jpegloss.js',
  'jpeg-baseline': 'assets/dwv/decoders/pdfjs/decode-jpegbaseline.js'
};

@Component({
  selector: 'app-dwv',
  templateUrl: './dicomviewer.component.html',
  styleUrls: ['./dicomviewer.component.scss']
})

export class DicomviewerComponent implements OnInit {
  public versions: any;
  public tools = ['Scroll', 'ZoomAndPan', 'WindowLevel', 'Draw']; //['Livewire', 'Filter', 'Floodfill']
  public selectedTool = 'Select Tool';
  public loadProgress = 0;
  public dataLoaded = false;
  public dataZoom = false;
  public dataSave = false;
  private dwvApp: any;
  private tags: any[];


  constructor(public dialog: MatDialog) {
    this.versions =
      {
        'dwv': dwv.getVersion(),
        'angular': VERSION.full
      };
  }

  ngOnInit() {
    // translate page
    dwv.i18nPage();

    // create app
    this.dwvApp = new dwv.App();

    // initialise app
    this.dwvApp.init({
      'containerDivId': 'dwv',
      'loaders': ['File', 'Url', 'GoogleDrive', 'Dropbox'],
      'tools': this.tools,
      'filters': ['Threshold', 'Sharpen', 'Sobel'],
      'shapes': ['Ruler'],
      'isMobile': true
    });


    // progress
    const self = this;
    this.dwvApp.addEventListener('load-progress', function (event)
    {
      self.loadProgress = event.loaded;
    });

    this.dwvApp.addEventListener('load-end', function (event)
    {
      // set data loaded flag
      self.dataLoaded = true;
      // set dicom tags
      self.tags = self.dwvApp.getTags();
      // set the selected tool
      if (self.dwvApp.isMonoSliceData() && self.dwvApp.getImage().getNumberOfFrames() === 1)
      {
        self.selectedTool = 'ZoomAndPan';
        self.dataZoom = true;
      }
      else
      {
        self.selectedTool = 'Scroll';
      }
    });

  }

  onChangeTool(tool): void
  {
    if (this.dwvApp)
    {
      this.selectedTool = tool;

      if (this.selectedTool == 'ZoomAndPan')
      {
        this.dataZoom = true;
      }
      else
      {
        this.dataZoom = false;
      }

      if (this.selectedTool == 'Draw')
      {
        this.dataSave = true;
      }
      else
      {
        this.dataSave = false;
      }


      this.dwvApp.onChangeTool({ currentTarget: { value: tool } });
    }
  }

  onReset(): void
  {
    if (this.dwvApp)
    {
      this.dwvApp.onDisplayReset();
    }
  }

  openTagsDialog(): void
  {
    this.dialog.open(TagsDialogComponent,
      {
        width: '60%',
        height: '70%',
        data: { title: 'DICOM Tags', value: this.tags }
      }
    );
  }

  saveDraw(): void
  {
    if (this.dwvApp)
    {
      /*
      Step1: If state.json file has to be downloaded then we can use below code in html
      <a mat-raised-button color="primary" (click)="saveDraw()" [disabled]="!dataSave" class="download-state" download="state.json">
       <i class="fa fa-save" > </i>
      </a> 
      Step2: this.dwvApp.onStateSave();
      */

      var state = new dwv.State();
      var jsonData = state.toJSON(this.dwvApp);

      if (jsonData != null && !isNullOrUndefined(jsonData))
      {
        console.log("State :" + jsonData);
      }
      else
      {
        alert("No Data available for - Draw Point Anotations");
      }
    }
    else
    {
      alert("Something Went wrong for - Draw Point Anotations");
    }
  }

  redoDraw(): void
  {
    if (this.dwvApp)
    {
      this.dwvApp.onRedo();
    }
    else
    {
      alert("Something Went wrong for - Redo");
    }
  }

  undoDraw(): void
  {
    if (this.dwvApp)
    {
      this.dwvApp.onUndo();
    }
    else
    {
      alert("Something Went wrong for - Undo")
    }
  }

  showInfo(): void
  {
    if (this.dwvApp)
    {
      this.dwvApp.toggleInfoLayerDisplay(); //toggleInfoLayerDisplay
    }
    else
    {
      alert("Something Went wrong for - Information")
    }
  }

}
