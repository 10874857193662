import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../_services/report.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from './../../../_services/common.service';
import { formatDate } from '@angular/common';
import { ExcelService } from '../../../_services/excel.service';
@Component({
  selector: 'app-printhfreport',
  templateUrl: './printhfreport.component.html'
})
export class PrinthfreportComponent implements OnInit {



  feacthchdata: any;
  searchdatalist: any;
  searchjson: any;

  currentdate: Date = new Date();
  PrintSearchlst: any[];
  constructor(private reportService: ReportService, private toastr: ToastrManager, private commonService: CommonService, private router: Router,private excelService: ExcelService,) {

  }

  ngOnInit() {
    this.feacthchdata = window;

    if (this.feacthchdata.PrintedBy == undefined) {
      window.close()
    } else {
      
      this.searchdatalist = this.feacthchdata.searchdata;
    }
  }

  printDiv() {

    window.print();
    return false;


  }



  public captureScreen() {

    this.PrintSearchlst = [];

    for (let i = 0; i < this.searchdatalist.length; i++) {

      var doctorprefixfrom = "";
      var doctorprefixto = "";

      if (this.searchdatalist[i].prefixBy == "Dr.") {
        doctorprefixfrom = "Dr.";
      } else {
        doctorprefixfrom = "";
      }



      if (this.searchdatalist[i].prefixTo == "Dr.") {
        doctorprefixto = "Dr.";
      } else {
        doctorprefixto = "";
      }

      let printjson = { "Sr No.": i + 1, "District": this.searchdatalist[i].fromDistrictName, "From Health Facility": this.searchdatalist[i].fromInstitutionName, "From User": doctorprefixfrom + " " + this.searchdatalist[i].sendBy, "To Health Facility": this.searchdatalist[i].toInstitutionName, "To User": doctorprefixto + " " + this.searchdatalist[i].sendTo, "Speciality": this.searchdatalist[i].toSpecialityName, "Consultation Date": formatDate(this.searchdatalist[i].createdDate, 'dd MMM, yyyy', 'en') }
      this.PrintSearchlst.push(printjson);


    }
    this.excelService.exportAsExcelFile(this.PrintSearchlst, 'Consultation');

  }



}
