import { Injectable } from '@angular/core';
import { HttpClient } from '../_services/index';
import { AppConfig } from '../_helpers/config';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  config: any;
  constructor(private http: HttpClient, config: AppConfig) { this.config = config }



  GetState() {




    return this.http.get("/Masters/GetStateMaster/0");
  }



  GetDistrict(stateid) {


    return this.http.get("/Masters/GetDistrictMaster/" + stateid);
  }


  GetCity(Districtid) {


    return this.http.get("/Masters/GetCityMaster/" + Districtid);
  }



  GetGender() {


    return this.http.get("/Masters/GetGenderMaster");
  }

  GetSpeciality() {


    return this.http.get("/Masters/GetSpecialityMaster");
  }


  GetInstitution() {


    return this.http.get("/Masters/GetInstitutionTypeMaster");
  }



  GetSpecialization() {


    return this.http.get("/Masters/GetSpecialityMaster");
  }


  GetQualification() {


    return this.http.get("/Masters/GetQualificationMaster");
  }




  GetDisability() {


    return this.http.get("/Masters/GetDisabilityMaster");
  }


  GetBloodGroup() {


    return this.http.get("/Masters/GetBloodGroupMaster");
  }


  GetRegion() {
    return this.http.get("/Masters/GetRegionMaster");
  }

  GetMaritalStatus() {
    return this.http.get("/Masters/GetMaritalStatusMaster");
  }

  ProblemSearch(term) {

    return this.http.get("/Consultation/GetProblemType/" + term);
  }


  AllergySearch(term) {

    return this.http.get("/Consultation/GetAllergyType/" + term);
  }




  ProvisionalDiagnosisSearch(term) {

    return this.http.get("/Consultation/GetDiagnosis/" + term);
  }



  RXSearch(term) {

    return this.http.get("/Consultation/GetMedicineType/" + term);
  }




  HCsendSearch(data) {


    return this.http.post("/HCRequest/HealthcubeTestRequest", data);
  }

  HCRecieved(data) {
    return this.http.post("/HCRequest/GetDiagnosticData", data);
  }



  GetSeverityMaster() {

    return this.http.get("/Masters/GetSeverityMaster");
  }



  GetDurationMaster() {

    return this.http.get("/Masters/GetDurationMaster");
  }



  GetHCTestMaster() {

    return this.http.get("/Masters/GetHCTestMaster");
  }


  GetDocByhospitallist(id) {
    return this.http.get("/Members/GetAllMembersByInstitutionId/" + id);
  }


  Institutionlist() {

    return this.http.get("/AdminInstitution/List/0");
  }



  addFeedback(objfeedback: any) {
    return this.http.post("/Feedback", objfeedback);
  }

  showFeedback() {
    return this.http.get("/Feedback/List");
  }



  ConnectedNetworkInstitutions() {
    
   // return this.http.get("/AdminInstitution/GetAllConnectedInstitutions");
    return this.http.get("/Consultation/GetAllConnectedInstitutions");
  }



  ConnectedInstitutions() {

    return this.http.get("/AdminInstitution/GetAllConnectedInstitutions");
  }





  ConnectedForwordInstitutions() {

    return this.http.get("/AdminInstitution/GetAllConnectedHUB");
  }


  ConnectedMembersByID(id) {
    return this.http.get("/Members/GetAllConnectedMembers/" + id);
  }


  ConnectedHUBMembersByID(id) {
    return this.http.get("/Members/GetAllConnectedHUBMembers/" + id);
  }



  GetToken() {

    return this.http.get("/eCollab/GenerateAuthToken");

  }



  GetCaseid(data: any) {

    return this.http.post("/eCollab/InserteCollabview", data);

  }

  GetViewToken() {
    return this.http.get("/eCollab/ViewToken");
  }


}


