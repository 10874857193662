import { Injectable } from '@angular/core';
import { HttpClient } from '../_services/index';
import { AppConfig } from '../_helpers/config';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  config: any;
  constructor(private http: HttpClient, config: AppConfig) { this.config = config }



  ConsulatationReport(data: any) {

    return this.http.post("/Reports/GetConsultationReport", data);
  }






  GetInstitutions() {

    return this.http.get("/Masters/GetInstitutions");
  }



  GetInstitutionsReport(id) {

    return this.http.get("/Reports/GetInstitutions/" + id);
  }




  GetInstitutionsReportsearch(id, Name) {

    return this.http.get("/Reports/GetInstitutions?Id=" + id + "&Name=" + Name);

  }



  GetMemberByInstitution() {

    return this.http.get("/Masters/GetMemberByInstitutionId/0");
  }



  GetMemberByInstitutionReport() {

    return this.http.get("/Reports/GetMemberByInstitutionId/0");
  }



  ConsulatationProblemReport(data: any) {

    return this.http.post("/Reports/GetProblemReport", data);
  }




  ConsulatationReceivedSummary(data: any) {

    return this.http.post("/Reports/GetReceivedSummaryReport", data);
  }




  ConsulatationDormantummary(data: any) {

    return this.http.post("/Reports/DormatSummaryReport", data);
  }




  ConsulatationPrintDormantummary(data: any) {

    return this.http.post("/Reports/DormatPrintSummaryReport", data);
  }




  ConsulatationRefferedSummary(data: any) {

    return this.http.post("/Reports/GetRefferedSummaryReport", data);
  }





  ConsulatationSearch(data: any) {
     
    return this.http.post("/Reports/DownloadConsultationReport", data);
  }
}
