import { Injectable, Inject } from '@angular/core';

@Injectable()

export class AppConfig {

  private _config;

  private _gglobal = '';

  constructor(@Inject('BASE_URL') baseUrl: string) {

    this._config = {

      PathAPI: baseUrl + "api",
      //PathAPI: 'http://localhost:1773/api'
      //  PathAPI: 'https://test.swasthyaingit.in/api'
      //  PathAPI: 'https://demo.esanjeevani.in/api'
      //PathAPI: 'https://cdac.instavc.com/api'

      //PathAPI: '10.228.12.121:9666/api'




    };

  }
  getglobal(): Object {
    return this._gglobal;
  }

  setglobal(global) {

    this._gglobal = global;

  }

  getconfig(): Object {
    return this._config;
  }

  getdefaulturl(): Object {
    return this._config.PathAPI;
  }
  get setting(): { [key: string]: string } {

    return this._config;

  }

  get(key: any) {

    return this._config[key];

  }

  public static securecookie = true;
};

