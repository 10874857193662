import { Injectable } from '@angular/core';
import { HttpClient } from '../../_services/index';
import { AppConfig } from '../../_helpers/config';

@Injectable()
export class UserService {
  config: any;
  constructor(private http: HttpClient, config: AppConfig) { this.config = config }




  changepwd(OldPassword: string, NewPassword: string, ConfirmPassword: string) {


    return this.http.post("/AdminProfile/ChangePassword", { OldPassword: OldPassword, Password: NewPassword, ConfirmPassword: ConfirmPassword });
  }


  changeprofilepic(File_Path: string, FileFlag: string, FileName: string) {

    return this.http.post("/AdminProfile/UpdateProfileImage", { "ImagePath": File_Path, "FileFlag": FileFlag, "FileName": FileName });
  }


  addInstitution(Institutionobj: any) {

    return this.http.post("/AdminInstitution", Institutionobj);
  }

  editInstitution(Institutionobj: any) {

    return this.http.put("/AdminInstitution", Institutionobj);
  }

  Institutionlist() {

    return this.http.get("/Reports/GetInstitutions/0");
    //return this.http.get("/AdminInstitution/List/0");
  }



  SearchInstitutionlist(data: any) {

    return this.http.post("/AdminInstitution/GetHealthfacilities", data);
  }



  GetInstitutionByID(id) {

    return this.http.get("/AdminInstitution/" + id);
  }


  GetRoleByID(hospid) {

    return this.http.get("/AdminInstitution/GetMasterSubMenu/" + hospid);
  }


  UserDefaultMenu(hospid) {

    return this.http.get("/AdminInstitution/GetUserDefaultMenu");
  }

  SaveAdminRole(obj) {
    return this.http.post("/AdminInstitution/InsertInstitutionMemberMenuMapping", obj);
  }

  AdminDashboard() {


    return this.http.get("/InstiDashboard/InstTypeInstiCount");
  }


  Adminlogout() {


    return this.http.get("/AdminProfile/Logout");
  }


  GetInstitutionByTypeId(id) {



    return this.http.get("/Network/GetInstitutionsByTypeId/" + id);
  }



  GetInstitutionByTypeIdSearch(id, Name) {

    return this.http.get("/Network/GetInstitutionsByTypeId?Id=" + id + "&Name=" + Name);

  }


  InstitutionlistSearch(id, Name) {

    return this.http.get("/Reports/GetInstitutions?Id=" + id + "&Name=" + Name);
    //return this.http.get("/AdminInstitution/List/0");
  }




  GetNetworkMappings(id) {

    return this.http.get("/Network/GetNetworkMappings/" + id);
  }


  GetNetworkMappingsDetail(id) {

    return this.http.get("/Network/GetNetworkFareList/" + id);
  }



  UpdateNetworkMappingsDetail(obj) {
    return this.http.post("/Network/UpdateNetworkDetail", obj);
  }


  DeleteNetworkMapping(obj) {
    return this.http.post("/Network/DeleteNetworkMapping", obj);
  }



  InsertNetworkMapping(obj) {
    return this.http.post("/Network/InsertNetworkMapping", obj);
  }

  AddMemberDetail(obj) {
    return this.http.post("/AdminInstitution/CreateUser", obj);
  }

  SearchMemberlist(data: any) {

    return this.http.post("/AdminInstitution/GetUsers", data);
  }


  GetMemberByID(id) {

    return this.http.get("/AdminInstitution/GetUserById/" + id);
  }



  EditMemberDetail(obj) {
    return this.http.post("/AdminInstitution/UpdateUser", obj);
  }





}
