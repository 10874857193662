import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../_helpers/config';
import { StorageService } from '../storage.service';
@Injectable()
export class DAuthenticationService {
  constructor(private http: HttpClient, private appConfig: AppConfig, private storageService: StorageService) { }


  private pathAPI = this.appConfig.getdefaulturl();
  login(username: string, password: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/SignInMember`, { UserName: username, Password: password, "sourceId": "1"})
      .pipe(map(user => {


        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('currentUserType', "3");
        }

        return user;
      }));
  }

  logout() {

  
    return this.http.get(`${this.pathAPI}/MemberProfile/Logout`);
  }



  loginOTPSend(username: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberLoginRequestOTP`, { UserName: username });
  }


  loginVerifyOTP(username: string, otp: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberLoginVerifyOTP`, { UserName: username, LoginOTP: otp })
      .pipe(map(user => {


        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('currentUserType', "3");
        }
        return user;
      }));
  }


  FGOTPSend(username: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberForgotRequestOTP`, { UserName: username });
  }



  FGVerifyOTPSend(username: string, otp: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberForgotVerifyOTP`, { UserName: username, LoginOTP: otp });
  }



  FGSavepwd(username: string, otp: string, NewPassword: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberChangePassword`, { UserName: username, LoginOTP: otp, Password: NewPassword });
  }

  DeleteTocken() {
    return this.http.get(`${this.pathAPI}/Auth/DeleteTocken`);
  }
}
