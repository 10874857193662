import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../_services/common.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-punjab',
  templateUrl: './punjab.component.html',
  styleUrls: ['./punjab.component.css']
})
export class PunjabComponent implements OnInit {


  constructor(
  
  ) {






 }

 


  ngOnInit() {

   



  }

}   //END OF CLASS
