import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../../_services';

import Swal from 'sweetalert2/dist/sweetalert2.js';


import { UserIdleService } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-patientlayout',
  templateUrl: './patientlayout.component.html',
  styleUrls: ['./patientlayout.component.css']
})
export class PatientlayoutComponent implements OnInit {
  returnUrl: string;
  profilepath: string;
  adminname: string;
  hospitalname: string;
  menulist: any;

  isCollapsed = false;
  mainBody = 'body_inner adminbody';
  mainClass = 'forced';
  username: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,

    private userIdle: UserIdleService,
    private alertService: AlertService,
    private cookieService: CookieService
  ) {



  }
  ngOnInit() {


    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';


    var localstore = JSON.parse(localStorage.getItem('currentPatientUser'));


    this.username = localstore.model.firstName + " " + localstore.model.middleName + " " + localstore.model.lastName;
   

  }


  setLeftMenu() {
    this.isCollapsed = !this.isCollapsed;


    if (!this.isCollapsed) {
      this.mainBody = 'body_inner adminbody';
      this.mainClass = 'forced';

    }
    else {
      this.mainBody = 'body_inner adminbody-void';
      this.mainClass = 'forced enlarged';

    }
  }


  Logout() {


    localStorage.removeItem('currentPatientUser');
    this.cookieService.delete('esanjhpaientcookie');
    window.location.href = "Covid19/Login";



  }
}
