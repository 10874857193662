import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../../_services';
import { AuthenticationService } from '../../../_services/admin/index';
import { UserService } from '../../../_services/admin/index';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserIdleService } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {
  returnUrl: string; username: string;
  isCollapsed = false;
  mainBody = 'body_inner adminbody';
  mainClass = 'forced';
  profilepath: string;

  StateAdmin: boolean = false;
  districtAdmin: boolean = false;

  stateurl: string;
  stateid: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService, private userIdle: UserIdleService,
    private alertService: AlertService, private UserService: UserService, private cookieService: CookieService) {



  }

  ngOnInit() {
    this.profilepath = 'assets/images/avatars/admin.png';

    var menustatus = JSON.parse(localStorage.getItem('MenuStaus'));
    if (!menustatus) {
      this.mainBody = 'body_inner adminbody';
      this.mainClass = 'forced';

    }
    else {
      this.mainBody = 'body_inner adminbody-void';
      this.mainClass = 'forced enlarged';

    }

    //location.reload();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    var localstore = JSON.parse(localStorage.getItem('currentAdminUser'));
    var usertype = localStorage.getItem('currentUserType');

    if (localstore == null || usertype == null) {

      window.location.href = "Admin/Login";

    }

    this.username = localstore.model.firstName + " " + localstore.model.middleName + " " + localstore.model.lastName;

    if (localstore.success) {
      if (localstore.model.imagePath) {
        this.profilepath = localstore.model.imagePath;
      }
    }



    this.userIdle.startWatching();

    this.userIdle.onTimerStart().subscribe(() => this.Logout());
    ///////////////Check Session Hijacking

    setInterval(() => {

      try {
        if (localstore.token != this.cookieService.get("esanjacookie")) {
          localStorage.removeItem('currentUser');
          localStorage.removeItem('currentAdminUser');
          this.Logout();
          window.location.href = "/Admin/Login";
        }

      }
      catch (e) {
        this.Logout();
      }
    }, 1000);

    ///////////////Check Session Hijacking



    ////////////////////////////////////////////// check state admin



    if (localstore.model.stateId != "0") {


      this.StateAdmin = true;
      this.stateurl = localstore.stateurl;

      this.stateid=localstore.model.stateId;
    } else {
      this.StateAdmin = false;
      this.stateid=localstore.model.stateId;
    }

    if (localstore.model.adminTypeId == "3") {

      this.districtAdmin = true;
    } else {
      this.districtAdmin = false;
    }




  }



  Stateadmincall() {


    window.location.href = this.stateurl;
  }


  setLeftMenu() {
    this.isCollapsed = !this.isCollapsed;
    localStorage.setItem('MenuStaus', JSON.stringify(this.isCollapsed));

    if (!this.isCollapsed) {
      this.mainBody = 'body_inner adminbody';
      this.mainClass = 'forced';

    }
    else {
      this.mainBody = 'body_inner adminbody-void';
      this.mainClass = 'forced enlarged';

    }
  }

  Logout() {



    this.UserService.Adminlogout()
      .pipe(first())
      .subscribe(

        data => {

          localStorage.removeItem('currentAdminUser');
          localStorage.removeItem('currentUserType');
          localStorage.removeItem('HCubeStatus');
          localStorage.removeItem('MenuStaus');
          window.location.href = "/Admin/Login";

        });
  }
}
