import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wbsite-layout',
  templateUrl: './wbsite-layout.component.html',
  styleUrls: ['./wbsite-layout.component.css']
})
export class WBSiteLayoutComponent implements OnInit {
  allimagelist: any = [];
  Images = ['../assets/images/wb/s1.jpg', '../assets/images/wb/s2.jpg', '../assets/images/wb/s3.jpg', '../assets/images/wb/s4.jpg', '../assets/images/wb/s5.jpg', '../assets/images/wb/s6.jpg', '../assets/images/wb/s7.jpg', '../assets/images/wb/s8.jpg', '../assets/images/wb/s9.jpg', '../assets/images/wb/s10.jpg']
  Images1 = ['../assets/images/wb/s11.jpg', '../assets/images/wb/s12.jpg', '../assets/images/wb/s14.jpg', '../assets/images/wb/s15.jpg', '../assets/images/wb/s16.jpg', '../assets/images/wb/s17.jpg', '../assets/images/wb/s18.jpg', '../assets/images/wb/s19.jpg']




  constructor() { }

  ngOnInit() {

  }



  SlideOptions = {

    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    margin: 10,
    nav: false,
    autoHeight: true,
    dots: false,
    responsive: {
      0: {
        items: 2,
        dots: true
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };


}