import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser;

        if (localStorage.getItem("currentUserType") == "1") {
            let currentUser = localStorage.getItem("currentAdminUser");


        } else if (localStorage.getItem("currentUserType") == "2") {
            let currentUser = localStorage.getItem("currentHospitalUser");


        } else {
            let currentUser = localStorage.getItem("currentUser");


        }



        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request);
    }
}