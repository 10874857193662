import { Injectable } from '@angular/core';
import { HttpClient } from '../../_services/index';
import { AppConfig } from '../../_helpers/config';

@Injectable()
export class HUserService {
  config: any;
  constructor(private http: HttpClient, config: AppConfig) { this.config = config }




  changepwd(OldPassword: string, NewPassword: string, ConfirmPassword: string) {


    return this.http.post("/MemberProfile/ChangePassword", { OldPassword: OldPassword, Password: NewPassword, ConfirmPassword: ConfirmPassword });
  }


  changeprofilepic(File_Path: string, FileFlag: string, FileName: string) {

    return this.http.post("/MemberProfile/UpdateProfileImage", { "ImagePath": File_Path, "FileFlag": FileFlag, "FileName": FileName });
  }


  GetInstitutionDetail() {

    return this.http.get("/MemberProfile/GetInstitutionDetail");
  }

  UpdateInstitutionDetail(data: any) {
 
    return this.http.post("/MemberProfile/UpdateInstitutionDetail", data);
  }


  AddDoctorDetail(data: any) {
 
    return this.http.post("/Members/InsertMember", data);
  }


  Doctorlist() {

    return this.http.get("/Members/List");
  }


  GetDoctorByID(id) {

    return this.http.get("/Members/" + id);
  }

  EditDoctor(data: any) {

    return this.http.put("/Members/UpdateMember", data);
  }



  GetMemberByID(id) {

    return this.http.get("/Members/GetMemberMenu/" + id);
  }


  SaveMemberRole(obj) {
    return this.http.post("/Members/InsertMemberMenuMapping", obj);
  }



  HospitalAdminlogout() {
    return this.http.get("/MemberProfile/Logout");
  }

  
}
