import { Component, OnInit } from '@angular/core';



import { ExcelService } from '../../../_services/excel.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-printproblemreport',
  templateUrl: './printproblemreport.component.html'
})
export class PrintproblemreportComponent implements OnInit {



  feacthchdata: any;
  searchdatalist: any;
  currentdate: Date = new Date();
  PrintSearchlst: any;
  loading: boolean;
  constructor(private excelService: ExcelService) { }




  ngOnInit() {

    this.feacthchdata = window;

    if (this.feacthchdata.PrintedBy == undefined) {
      window.close()
    } else {

      this.searchdatalist = this.feacthchdata.searchdata
    }
  }


  public captureScreen() {

    this.PrintSearchlst = [];
    this.loading = true;
    for (let i = 0; i < this.searchdatalist.length; i++) {
      var doctorprefix = "";

      if (this.searchdatalist[i].prefixSendBy == "Dr.") {
        doctorprefix = "Dr.";
      } else {
        doctorprefix = "";
      }
      let printjson = { "Sr No.": i + 1, "Problem": this.searchdatalist[i].problemName, "District": this.searchdatalist[i].fromDistrictName, "From Health Facility": this.searchdatalist[i].fromInstitutionName, "From User": doctorprefix + " " + this.searchdatalist[i].sendBy, "Speciality": this.searchdatalist[i].toSpecialityName, "Consultation Date": formatDate(this.searchdatalist[i].createdDate, 'dd MMM, yyyy', 'en') }
      this.PrintSearchlst.push(printjson);

    }


    this.excelService.exportAsExcelFile(this.PrintSearchlst, 'Consultation');
    this.loading = false;
  }



  printDiv() {

    window.print();
    return false;


  }

}
