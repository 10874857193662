import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../../_services';
import { HAuthenticationService } from '../../../_services/hospital/index';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { HUserService } from '../../../_services/hospital/index';
import { UserIdleService } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-hospitallayout',
  templateUrl: './hospitallayout.component.html',
  styleUrls: ['./hospitallayout.component.css']
})
export class HospitallayoutComponent implements OnInit {
  returnUrl: string;
  profilepath: string;
  adminname: string;
  hospitalname: string;
  menulist: any;

  isCollapsed = false;
  mainBody = 'body_inner adminbody';
  mainClass = 'forced';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: HAuthenticationService,
    private _HUserService: HUserService,
    private userIdle: UserIdleService,
    private alertService: AlertService,
    private cookieService: CookieService
  ) {



  }
  ngOnInit() {


    this.profilepath = 'assets/images/avatars/admin.png';

    var menustatus = JSON.parse(localStorage.getItem('MenuStaus'));

    if (!menustatus) {
      this.mainBody = 'body_inner adminbody';
      this.mainClass = 'forced';

    }
    else {
      this.mainBody = 'body_inner adminbody-void';
      this.mainClass = 'forced enlarged';

    }
    var localstore = JSON.parse(localStorage.getItem('currentHospitalUser'));
    var usertype = localStorage.getItem('currentUserType');
    if (localstore == null || usertype == null) {
      
      window.location.href = "Login";
      // Swal.fire({
      //   title: 'Oops...',
      //   text: 'Invalid Request!',
      //   type: 'error',
      //   showCancelButton: false,
      //   confirmButtonColor: '#133656',
      //   cancelButtonColor: '#E5C151',
      //   confirmButtonText: 'Ok!',
      //   allowOutsideClick: false,
      //   allowEscapeKey: false,
      //   allowEnterKey: false

      // }).then((result) => {
      //   if (result.value) {
      //     window.location.href = "Login";

      //     //this.router.navigateByUrl('Hospital/Login');
      //   }
      // })
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';



    if (localstore.success) {
      if (localstore.model.imagePath) {
        this.profilepath = localstore.model.imagePath;
      }

      this.hospitalname = localstore.model.institutionName;
      this.adminname = localstore.model.firstName + " " + localstore.model.middleName + " " + localstore.model.lastName;
      this.menulist = localstore.model.lstSubMenuModel;


    }
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(() => this.Logout());

    ///////////////Check Session Hijacking
    setInterval(() => {

      try {
        if (localstore.token != this.cookieService.get("esanjhcookie")) {
          localStorage.removeItem('currentHospitalUser');
          localStorage.removeItem('currentUserType');
          this.Logout();
          window.location.href = "/Login";
        }
      }
      catch (e) {
        this.Logout();
      }
    }, 1000);
    ///////////////Check Session Hijacking
  }


  setLeftMenu() {
    this.isCollapsed = !this.isCollapsed;
    localStorage.setItem('MenuStaus', JSON.stringify(this.isCollapsed));

    if (!this.isCollapsed) {
      this.mainBody = 'body_inner adminbody';
      this.mainClass = 'forced';

    }
    else {
      this.mainBody = 'body_inner adminbody-void';
      this.mainClass = 'forced enlarged';

    }
  }


  Logout() {

    this._HUserService.HospitalAdminlogout()
      .pipe(first())
      .subscribe(

        data => {
          localStorage.removeItem('currentHospitalUser');
          localStorage.removeItem('currentUserType');
          localStorage.removeItem('HCubeStatus');
          localStorage.removeItem('MenuStaus');
          this.cookieService.delete('esanjhcookie');
          window.location.href = "/login";

        });

  }
}
