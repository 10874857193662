import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';

import { StorageService } from './storage.service';
import { Processing, CloseModal } from '../app.helpers';
import { AppConfig } from './../_helpers/config';
import { Observable } from 'rxjs';
import { map, catchError, first } from 'rxjs/operators'
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CookieService } from 'ngx-cookie-service';

import 'sweetalert2/src/sweetalert2.scss';
@Injectable()
export class HttpClient {
  config: any;
  BaseURL: string;
  constructor(private http: Http, private storage: StorageService, private appConfig: AppConfig, public storageService: StorageService, private router: Router, private cookieService: CookieService) {


    this.config = this.appConfig.getconfig();
    this.BaseURL = this.config.PathAPI;

  }



  createAuthorizationHeader(headers: Headers) {



    if (this.storage.get("currentUserType") != null) {

      if (this.storage.get("currentUserType") == "1") { //Admin
        let currentUser = this.storage.get("currentAdminUser");
        headers.append('Authorization', 'Bearer ' + currentUser['token']);
        if (currentUser['token'] != this.cookieService.get("esanjacookie")) {
          this.clearCookiesStorage();
          window.location.href = "/Admin/Login";
        }

      } else if (this.storage.get("currentUserType") == "2") { //Hospital 

        let currentUser = this.storage.get("currentHospitalUser");
        headers.append('Authorization', 'Bearer ' + currentUser['token']);
        if (currentUser['token'] != this.cookieService.get("esanjhcookie")) {
          this.clearCookiesStorage();
          window.location.href = "/Login";
        }

      } else {//Doctor
        let currentUser = this.storage.get("currentUser");
        headers.append('Authorization', 'Bearer ' + currentUser['token']);

        if (currentUser['token'] != this.cookieService.get("esanjcookie")) {
          this.clearCookiesStorage();
          window.location.href = "/Login";
        }
      }

    } else {


      this.clearCookiesStorage();
      window.location.href = "/Login";
    }



  }

  clearCookiesStorage() {
    localStorage.removeItem('currentUserType');
    localStorage.removeItem('currentAdminUser');
    localStorage.removeItem('currentHospitalUser');
    localStorage.removeItem('currentUser');;

    var date = new Date();
    date.setTime(date.getTime() + (1 * 10));
    this.cookieService.set('esanjcookie', '', date, "", "", AppConfig.securecookie);
    this.cookieService.set('esanjhcookie', '', date, "", "", AppConfig.securecookie);
    this.cookieService.set('esanjacookie', '', date, "", "", AppConfig.securecookie);
  }
  get(url) {


    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    // Processing("#Processing", "block");

    return this.http.get(this.BaseURL + url, {
      headers: headers
    }).pipe(
      map((res: Response) => {



        //  this.appConfig.setglobal(this.storage.get("currentUser"));
        return this.checkRespose(res);
      }), catchError(
        this.handleError
      ));


  }
  delete(url) {
    /// Processing("#Processing", "block");
    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http.delete(this.BaseURL + url, {
      headers: headers
    }).pipe(map(this.handlerData),
      catchError(this.handleError));
  }
  post(url, data) {


    ///  Processing("#Processing", "block");
    let headers = new Headers();

    this.createAuthorizationHeader(headers); return this.http.post(this.BaseURL + url, data, {
      headers: headers
    }).pipe(
      map((res: Response) => {
        //  this.appConfig.setglobal(this.storage.get("currentUser"));
        return this.checkRespose(res);
      }), catchError(
        this.handleError
      ));
  }
  put(url, data) {
    // Processing("#Processing", "block");
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this.http.put(this.BaseURL + url, data, {
      headers: headers
    }).pipe(
      map((res: Response) => {
        //  this.appConfig.setglobal(this.storage.get("currentUser"));
        return this.checkRespose(res);
      }), catchError(

        this.handleError

      ));
  }
  private handleError(error: Response) {

    return Observable.throw({ requestStatus: 0, msg: "Server error", arr: [] });
  }

  private handlerData(error: Response) {

    //Processing("#Processing", "none");
    return error.json();
  }

  private checkRespose(res) {
    var result = res.json();


    if (result.success == false && (result.requestCode == 401 || result.requestCode == 403)) {

      this.clearCookiesStorage();

      var currentUserType = localStorage.getItem('currentUserType');

      Swal.fire({
        title: 'Oops...',
        text: 'You have been logged out. Some exceptions were encountered. Kindly re-login.',
        type: 'error',
        showCancelButton: false,
        confirmButtonColor: '#133656',
        cancelButtonColor: '#E5C151',
        confirmButtonText: 'Ok!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false

      }).then((result) => {
        if (result.value) {
          if (currentUserType == "1") {

            window.location.href = "/Admin/Login";
          } else {
            window.location.href = "/Login";
          }
          this.storageService.SignalRAdapter.CloseChatGrid(this.storageService.otherEndChatId);
          this.storageService.isConnectionEstablished = false;
          this.storageService.isConsultationActive = false;
          this.storageService.SignalRAdapter.LeaveConnference();

        }
      })


      return res.json();
    } if (result.Success == false && (result.RequestCode == 401 || result.RequestCode == 403)) {

      this.clearCookiesStorage();

      var currentUserType = localStorage.getItem('currentUserType');

      Swal.fire({
        title: 'Oops...',
        text: 'You have been logged out. Some exceptions were encountered. Kindly re-login.',
        type: 'error',
        showCancelButton: false,
        confirmButtonColor: '#133656',
        cancelButtonColor: '#E5C151',
        confirmButtonText: 'Ok!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false

      }).then((result) => {
        if (result.value) {
          if (currentUserType == "1") {

            window.location.href = "/Admin/Login";
          } else {
            window.location.href = "/Login";
          }
          this.storageService.SignalRAdapter.CloseChatGrid(this.storageService.otherEndChatId);
          this.storageService.isConnectionEstablished = false;
          this.storageService.isConsultationActive = false;
          this.storageService.SignalRAdapter.LeaveConnference();

        }
      })


      return res.json();
    } else if (result.success == false && (result.requestCode == 450)) {

      this.clearCookiesStorage();

      var currentUserType = localStorage.getItem('currentUserType');
      Swal.fire({
        title: 'Oops...',
        text: 'You have been logged out. Some exceptions were encountered. Kindly re-login.',
        type: 'error',
        showCancelButton: false,
        confirmButtonColor: '#133656',
        cancelButtonColor: '#E5C151',
        confirmButtonText: 'Ok!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false

      }).then((result) => {
        if (result.value) {
          if (currentUserType == "1") {
            window.location.href = "/Admin/Login";
          } else {
            window.location.href = "/Login";
          }
          this.storageService.SignalRAdapter.CloseChatGrid(this.storageService.otherEndChatId);
          this.storageService.isConnectionEstablished = false;
          this.storageService.isConsultationActive = false;
          this.storageService.SignalRAdapter.LeaveConnference();

        }
      })


      return res.json();
    }
    else if (result.Success == false && (result.RequestCode == 450)) {

      this.clearCookiesStorage();

      var currentUserType = localStorage.getItem('currentUserType');
      Swal.fire({
        title: 'Oops...',
        text: 'You have been logged out. Some exceptions were encountered. Kindly re-login.',
        type: 'error',
        showCancelButton: false,
        confirmButtonColor: '#133656',
        cancelButtonColor: '#E5C151',
        confirmButtonText: 'Ok!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false

      }).then((result) => {
        if (result.value) {
          if (currentUserType == "1") {
            window.location.href = "/Admin/Login";
          } else {
            window.location.href = "/Login";
          }
          this.storageService.SignalRAdapter.CloseChatGrid(this.storageService.otherEndChatId);
          this.storageService.isConnectionEstablished = false;
          this.storageService.isConsultationActive = false;
          this.storageService.SignalRAdapter.LeaveConnference();

        }
      })


      return res.json();
    }
    else {
      return res.json();
    }

  }
}
