import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../_helpers/config';
import { StorageService } from '../storage.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HAuthenticationService {


  constructor(private http: HttpClient, private appConfig: AppConfig, private storageService: StorageService, private cookieService: CookieService) { }


  private pathAPI = this.appConfig.getdefaulturl();
  login(username: string, password: string,capchavalue:string) {

    return this.http.post<any>(`${this.pathAPI}/Auth/SignInMember`, { UserName: username, Password: password, "sourceId": "1",CapchaValue:capchavalue})
      .pipe(map(user => {


     
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
       

          
         if (user.model.isMaster == true) {

           this.cookieService.set('esanjhcookie', user.token, 1, "", "", AppConfig.securecookie);
            localStorage.setItem('currentHospitalUser', JSON.stringify(user));
            localStorage.setItem('currentUserType', "2");
            
          }else{
           this.cookieService.set('esanjcookie', user.token, 1, "", "", AppConfig.securecookie);
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('currentUserType', "3");
            
          }
        
          localStorage.removeItem('HCubeStatus');
          if (user.model.lstSubMenuModel) {
            for (let menuname of user.model.lstSubMenuModel) {
              if (menuname.subMenuId == "10") {
                localStorage.setItem('HCubeStatus', "1");
                break;
              }

            }
          }

        }

        

        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentHospitalUser');
    localStorage.removeItem('currentUserType');
    localStorage.removeItem('HCubeStatus');
    localStorage.removeItem('MenuStaus');
    this.storageService.hubConnection.stop();
    return this.http.get(`${this.pathAPI}/Auth/MemberLogout`);
  }


  loginOTPSend(username: string,para:string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberLoginRequestOTP`, { UserName: username,para:para });
  }


  loginVerifyOTP(username: string, otp: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberLoginVerifyOTP`, { UserName: username, LoginOTP: otp })
      .pipe(map(user => {



        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
       

        
        

      

          if (user.model.isMaster == true) {

            this.cookieService.set('esanjhcookie', user.token, 1, "", "", AppConfig.securecookie);
            localStorage.setItem('currentHospitalUser', JSON.stringify(user));
            localStorage.setItem('currentUserType', "2");
            
          }else{
            this.cookieService.set('esanjcookie', user.token, 1, "", "", AppConfig.securecookie);
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('currentUserType', "3");
            
          }
        
          localStorage.removeItem('HCubeStatus');
          if (user.model.lstSubMenuModel) {
            for (let menuname of user.model.lstSubMenuModel) {
              if (menuname.subMenuId == "10") {
                localStorage.setItem('HCubeStatus', "1");
                break;
              }

            }
          }

        }

        return user;
      }));
  }

 
  FGOTPSend(username: string, para: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberForgotRequestOTP`, { UserName: username,para: para});
  }



  FGVerifyOTPSend(username: string, otp: string,para: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberForgotVerifyOTP`, { UserName: username, LoginOTP: otp,para: para });
  }



  FGSavepwd(username: string, otp: string, NewPassword: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/MemberChangePassword`, { UserName: username, LoginOTP: otp, Password: NewPassword });
  }


  
  DeleteTocken() {
    return this.http.get(`${this.pathAPI}/Auth/DeleteTocken`);
  }
}
