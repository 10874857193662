import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../_helpers/config';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, private appConfig: AppConfig, private cookieService: CookieService) { }


  private pathAPI = this.appConfig.getdefaulturl();
  login(username: string, password: string, capchavalue: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/SignInAdmin`, { UserName: username, Password: password, Capchavalue: capchavalue })
      .pipe(map(user => {


        // login successful if there's a jwt token in the response
        if (user && user.token) {

          this.cookieService.set('esanjacookie', user.token, 1, "", "", AppConfig.securecookie);

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentAdminUser', JSON.stringify(user));
          localStorage.setItem('currentUserType', "1");
        }

        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out

    return this.http.get("https://esanjeevani.in/api/AdminProfile/Logout");
  }


  loginOTPSend(username: string, para: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/AdminLoginRequestOTP`, { UserName: username, para: para });
  }


  loginVerifyOTP(username: string, otp: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/AdminLoginVerifyOTP`, { UserName: username, LoginOTP: otp })
      .pipe(map(user => {


        // login successful if there's a jwt token in the response
        if (user && user.token) {
          this.cookieService.set('esanjacookie', user.token, 1, "", "", AppConfig.securecookie);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentAdminUser', JSON.stringify(user));
          localStorage.setItem('currentUserType', "1");
        }
        return user;
      }));
  }


  FGOTPSend(username: string, para: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/AdminForgotRequestOTP`, { UserName: username, para: para });
  }



  FGVerifyOTPSend(username: string, otp: string, para: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/AdminForgotVerifyOTP`, { UserName: username, LoginOTP: otp, para: para });
  }



  FGSavepwd(username: string, otp: string, NewPassword: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/AdminChangePassword`, { UserName: username, LoginOTP: otp, Password: NewPassword });
  }


  DeleteTocken() {
    return this.http.get(`${this.pathAPI}/Auth/DeleteTocken`);
  }




  OPDashboardVCCount() {
    return this.http.get(`${this.pathAPI}/Integration/AdminInstVCCount`);
  }


  opcompareconsultbystates() {
    return this.http.get(`${this.pathAPI}/Integration/AdminCompare`);
  }


  opgenderCountService() {
    return this.http.get(`${this.pathAPI}/Integration/AdminPatientGenderDetail`);
  }



  
  opCount() {
    return this.http.get(`${this.pathAPI}/Integration/Counters`);
  }

}
