import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../_helpers/config';
import { StorageService } from '../storage.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class PAuthenticationService {


  constructor(private http: HttpClient, private appConfig: AppConfig, private storageService: StorageService, private cookieService: CookieService) { }


  private pathAPI = this.appConfig.getdefaulturl();
  login(username: string, password: string, capchavalue: string) {

    return this.http.post<any>(`${this.pathAPI}/Auth/VerifyPatientLogin`, { UserName: username, Password: password, "sourceId": "1", CapchaValue: capchavalue })
      .pipe(map(user => {



        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes


          this.cookieService.set('esanjhpaientcookie', user.token, 1, "", "", AppConfig.securecookie);
          localStorage.setItem('currentPatientUser', JSON.stringify(user));

        }



        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentPatientUser');
    this.storageService.hubConnection.stop();
    return this.http.get(`${this.pathAPI}/Auth/MemberLogout`);
  }


  RegistrationOTPSend(mobile: string, para: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/CovidRequestRegistrationOTP`, { Mobile: mobile, para: para });
  }


  RegistrationVerifyOTP(mobile: string, otp: string) {
    return this.http.post<any>(`${this.pathAPI}/Auth/CovidVerifyRegistrationOTP`, { Mobile: mobile, OTP: otp });
    
  }



  RegistrationPatient(data: any) {
    return this.http.post<any>(`${this.pathAPI}/Auth/CovidPatientRegistration`, data);
  }


  DeleteTocken() {
    return this.http.get(`${this.pathAPI}/Auth/DeleteTocken`);
  }




  GetState() {


    return this.http.get(`${this.pathAPI}/CovidCommon/GetStateMaster/0`);

  }



  GetDistrict(stateid) {


    return this.http.get(`${this.pathAPI}//CovidCommon/GetDistrictMaster/` + stateid);
  }


  GetCity(Districtid) {


    return this.http.get(`${this.pathAPI}/CovidCommon/GetCityMaster/` + Districtid);
  }



  GetGender() {


    return this.http.get(`${this.pathAPI}/CovidCommon/GetGenderMaster`);
  }


}
