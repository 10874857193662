import { Component, OnInit } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ExcelService } from '../../../_services/excel.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-printconsulationstatusreport',
  templateUrl: './printconsulationstatusreport.component.html'
})
export class PrintconsulationstatusreportComponent implements OnInit {



  feacthchdata: any;
  searchdatalist: any;
  currentdate: Date = new Date();
  PrintSearchlst: any[];
  loading: boolean;
  constructor(private excelService: ExcelService) { }




  ngOnInit() {

    this.feacthchdata = window;

    if (this.feacthchdata.PrintedBy == undefined) {
      window.close()
    } else {

      this.searchdatalist = this.feacthchdata.searchdata
    }
  }




  public captureScreen() {
  
    this.PrintSearchlst = [];
    for (let i = 0; i < this.searchdatalist.length; i++) {

      var doctorprefixfrom = "";
      var doctorprefixto = "";

      if (this.searchdatalist[i].prefixBy == "Dr.") {
        doctorprefixfrom = "Dr.";
      } else {
        doctorprefixfrom = "";
      }



      if (this.searchdatalist[i].prefixTo == "Dr.") {
        doctorprefixto = "Dr.";
      } else {
        doctorprefixto = "";
      }


      let printjson = { "Sr No.": i + 1, "Consultation Id": this.searchdatalist[i].consultationId, "District": this.searchdatalist[i].fromDistrictName, "From Health Facility": this.searchdatalist[i].fromInstitutionName, "From User": doctorprefixfrom + " " + this.searchdatalist[i].sendBy, "To Health Facility": this.searchdatalist[i].toInstitutionName, "To User": doctorprefixto + " " + this.searchdatalist[i].sendTo, "Speciality": this.searchdatalist[i].toSpecialityName, "Status": this.searchdatalist[i].status, "Consultation Date": formatDate(this.searchdatalist[i].createdDate, 'dd MMM, yyyy', 'en') }
      this.PrintSearchlst.push(printjson);

    }


    this.excelService.exportAsExcelFile(this.PrintSearchlst, 'Consultation');
    this.loading = false;

  }




  downloadpdf() {



    var data = document.getElementById('print-section');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('Report.pdf'); // Generated PDF

    });
    //this.subscription.unsubscribe()
  }



  printDiv() {

    window.print();
    return false;


  }

}
