import { Component, OnInit } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ExcelService } from '../../../../_services/excel.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-printenrollmentstatus',
  templateUrl: './printenrollmentstatus.component.html',
  styleUrls: ['./printenrollmentstatus.component.css']
})
export class PrintenrollmentstatusComponent implements OnInit {
  feacthchdata: any;
  searchdatalist: any;
  currentdate: Date = new Date();
  PrintSearchlst: any[];
  loading: boolean;
  constructor(private excelService: ExcelService) { }

  ngOnInit() {
    this.feacthchdata = window;

    this.searchdatalist = this.feacthchdata.searchdata
    console.log(this.searchdatalist);
  }
  printDiv() {

    window.print();
    return false;


  }
  public captureScreen() {
   this.PrintSearchlst = [];
    for (let i = 0; i < this.searchdatalist.length; i++) {
      let printjson = {
        "Sr No.": i + 1, "District Name": this.searchdatalist[i].districtName,
        "City Name": this.searchdatalist[i].cityName,
        "Sub Center": this.searchdatalist[i].subCenter,
        "User Name": this.searchdatalist[i].userName,
        "Enrollment Date": formatDate(this.searchdatalist[i].entryDate, 'dd MMM, yyyy', 'en'),
        "Enrollment Completed": this.searchdatalist[i].familyMemberEntryDone,
        "CBAC Completed": this.searchdatalist[i].cbacEntryDone
      }
      this.PrintSearchlst.push(printjson);
     
    }
    this.excelService.exportAsExcelFile(this.PrintSearchlst, 'Enrollmentstatus');
    this.loading = false;
  }
}
