import { Injectable } from '@angular/core';
import { HttpClient } from '../../_services/index';
import { AppConfig } from '../../_helpers/config';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  config: any;

  constructor(private http: HttpClient, config: AppConfig) {

    this.config = config;

  }

  DashboardTabs(stateId, distId) {

    var distQuery = '';
    if (distId) {
      distQuery = '&districtId=' + distId
    }

    return this.http.get("/AdminDashboard/AdminInstDashLanding?stateid=" + stateId + distQuery);

  }

  getDashboardChartsByFilterService(stateId, filterType, distId) {
    var distQuery = '';
    if (distId) {
      distQuery = '&DistrictId=' + distId
    }
    var url = '/AdminDashboard/AdminAvgConsStateDists?StateId=' + stateId + distQuery;
    if (filterType == 'averageconsultations') {

      url = '/AdminDashboard/AdminAvgConsStateDists?StateId=' + stateId + distQuery;

    } else if (filterType == 'totalhealthfacilities') {
      url = '/AdminDashboard/AdminHealthStateDists?StateId=' + stateId + distQuery;
    } else if (filterType == 'totalconsultations') {
      url = '/AdminDashboard/AdminConsStateDists?StateId=' + stateId + distQuery;
    } else if (filterType == 'totalpatients') {
      url = '/AdminDashboard/AdminPatientStateDists?StateId=' + stateId + distQuery;
    } else if (filterType == 'totalprescription') {
      url = '/AdminDashboard/AdminPresStateDists?StateId=' + stateId + distQuery;
    } else if (filterType == 'totalusers') {
      url = '/AdminDashboard/AdminUserStateDists?StateId=' + stateId + distQuery;
    }

    return this.http.get(url);
  }


  averageConsultationService(stateId) {

    return this.http.get("/AdminDashboard/AdminAvgConsStateDists?StateId=" + stateId);

  }

  totalHealthService(stateId) {


    return this.http.get("/AdminDashboard/AdminHealthStateDists?StateId=" + stateId);
  }

  totalConsultationService() {
    return this.http.get("/AdminDashboard/AdminConsultations");
  }

  prescriptionService() {
    return this.http.get("/AdminDashboard/AdminPrescriptions");
  }

  totalPatientsService() {

    return this.http.get("/AdminDashboard/AdminPatients");
  }

  referalService() {
    return this.http.get("/AdminDashboard/AdminReferrals");
  }

  genderCountService(stateId, distId) {
    var distQuery = '';
    if (distId) {
      distQuery = '&DistrictId=' + distId
    }
    return this.http.get("/AdminDashboard/AdminPatientGenderDash?StateId=" + stateId + distQuery);
  }


  EMRCountService(stateId, distId) {
    var distQuery = '';
    if (distId) {
      distQuery = '&DistrictId=' + distId
    }
    return this.http.get("/AdminDashboard/AdminEMRStateDists?StateId=" + stateId + distQuery);
  }

  daignosticService(stateId, distId) {
    var distQuery = '';
    if (distId) {
      distQuery = '&DistrictId=' + distId
    }
    return this.http.get("/AdminDashboard/AdminDiagnosticsStateDists?StateId=" + stateId + distQuery);
  }

  getDropDown(stateId) {



    return this.http.get("/AdminDashboard/AdminStateDists?StateId=" + stateId);
  }

  compareconsultbystates(stateoneval, statetwoval, statethreeval, timestamp, stateDist) {

    return this.http.get("/AdminDashboard/AdminCompare?StateDist=" + stateDist + "&State1=" + stateoneval + "&State2=" + statetwoval + "&State3=" + statethreeval + "&DWYLabel=" + timestamp);

  }
  comparestateOne(stateId) {

    return this.http.get("/AdminDashboard/AdminStateDists?StateId=" + stateId);
  }


  compareAllstate() {

    return this.http.get("/AdminDashboard/GetAllStateDetails");
  }



  DashboardHUBSPOCKcount(TypeId, RecordId) {

    return this.http.get("/AdminDashboard/GetActiveMembersCount?TypeId=" + TypeId + "&RecordId=" + RecordId);

  }



  DashboardVCCount(stateId, distId) {
 
    var distQuery = '';
    if (distId) {
      distQuery = '&districtId=' + distId
    }

    return this.http.get("/AdminDashboard/AdminInstVCCount?stateid=" + stateId + distQuery);

  }




  ///////////////////////////////////////new dashboard //////////////



  DashboardTabCountera(data: any) {

    return this.http.post("/AdminDashboard/GetCounters", data)

  }



  DashboardGenderCountera(data: any) {

    return this.http.post("/AdminDashboard/GetGenderCounters", data)

  }


  GetBacthActiveMembersCount(data: any) {

    return this.http.post("/AdminDashboard/GetBacthActiveMembersCount", data)

  }

  GetBacthVCCount(data: any) {

    return this.http.post("/AdminDashboard/GetBacthVCCount", data)

  }


  
  GetConsultationPerDay(data: any) {

    return this.http.post("/AdminDashboard/GetConsultationPerDay", data)

  }



  GetMapCounters(data: any) {

    return this.http.post("/AdminDashboard/GetMapCounters", data)

  }


  GetDistrictLineChart(data: any) {

    return this.http.post("/AdminDashboard/GetDistrictLineChart", data)

  }


  /////////////////////////////////////////////////////////////////////





  ///////////////////////////////wise Dashboard service api



  cumulativeconsults(data: any) {

    return this.http.post("/Wish/cumulativeConsult", data)

  }

  
  genderAgeaggregate(data: any) {

    return this.http.post("/Wish/genderAgeaggregate", data)

  }



  consultsData(data: any) {

    return this.http.post("/Wish/consultsData", data)

  }

}
