import { Injectable } from '@angular/core';
import { HttpClient } from '../../_services/index';
import { AppConfig } from '../../_helpers/config';

@Injectable()
export class DUserService {
  config: any;
  constructor(private http: HttpClient, config: AppConfig) { this.config = config }




  changepwd(OldPassword: string, NewPassword: string, ConfirmPassword: string) {


    return this.http.post("/MemberProfile/ChangePassword", { OldPassword: OldPassword, Password: NewPassword, ConfirmPassword: ConfirmPassword });
  }


  changeprofilepic(File_Path: string, FileFlag: string, FileName: string) {

    return this.http.post("/MemberProfile/UpdateProfileImage", { "ImagePath": File_Path, "FileFlag": FileFlag, "FileName": FileName });
  }


  GetInstitutionDetail() {

    return this.http.get("/MemberProfile/GetInstitutionDetail");
  }

  UpdateInstitutionDetail(data: any) {

    return this.http.post("/MemberProfile/UpdateInstitutionDetail", data);
  }


  AddPatientDetail(data: any) {

    return this.http.post("/Patient", data);
  }
  EditPatientDetail(data: any) {


    return this.http.put("/Patient", data);
  }

  Patientlist(data: any) {

    return this.http.post("/Patient/List", data);
  }


  GetPatientByID(id) {

    return this.http.get("/Patient/" + id);
  }


  Doctorlist() {

    return this.http.get("/Members/List");
  }


  GetDoctorByID(id) {

    return this.http.get("/Members/" + id);
  }
  UploadDocSignature(data: any) {

    return this.http.post("/MemberProfile/FileUpload", data);
  }

  GetDoctorprescriptionByID(id) {

    return this.http.get("/MemberProfile/GetMemberProfile/" + id);
  }

  EditDoctor(data: any) {

    return this.http.put("/Members", data);
  }
  GetDoctorProfile() {

    return this.http.get("/MemberProfile");
  }
  EditDoctorProfile(data: any) {

    return this.http.post("/MemberProfile/UpdateProfile", data);
  }


  SaveMemberRole(obj) {
    return this.http.post("/AdminInstitution/InsertInstitutionMemberMenuMapping", obj);
  }



  addPrescription(obj) {
    return this.http.post("/Consultation/InsertConsultation", obj);
  }

  addDraft(obj) {
    return this.http.post("/Consultation/DraftConsultation", obj);
  }


  InProcessConsultaion(obj) {
    return this.http.post("/Consultation/InProcessConsultation", obj);
  }

  SynceConsultaion(obj) {
    return this.http.post("/Consultation/SyncConsultation", obj);
  }


  Fecthstatususer() {
    return this.http.get("/Consultation/CheckAvailability");
  }



  Fecthstatusercall(id) {
    return this.http.get("/Consultation/CheckUserAvailability/" + id);

  }



  CheckRecallStatus(id) {
    return this.http.get("/Consultation/CheckRecallStatus/" + id);

  }

  InProcessLog(id) {
    return this.http.get("/Consultation/InProcessLog/" + id);

  }



  CheckRealStatus(id) {
    return this.http.get("/Home/CheckRealStatus/" + id);

  }





  Fecthchangestatuser() {
    return this.http.get("/Members/SetStatus");

  }

  GetCaseReceived(data: any) {

    return this.http.post("/Consultation/ReceivedList", data);
  }

  GetCaseReffered(data: any) {

    return this.http.post("/Consultation/ReferredList", data);
  }


  GetConsultationsViewsByID(id) {

    return this.http.get("/Consultation/PatientConsultationList/" + id);
  }

  UploadMedicalDoc(data: any) {

    return this.http.post("/Consultation/FileUpload", data);
  }

  addspecilPrescription(obj) {
    return this.http.post("/Consultation/InsertResponseConsultation", obj);
  }



  UpdateConsultationStatus(obj) {
    return this.http.post("/Consultation/UpdateConsultationStatus", obj);
  }


  ForwardConsultation(obj) {
    return this.http.post("/Consultation/ForwardConsultation", obj);
  }

  GetConsultationRecordByID(id) {


    return this.http.get("/Reports/GetConsultationRecordByID/" + id);
  }

  GetConsultationByID(id) {


    return this.http.get("/Consultation/" + id);
  }


  DownloadPrescription(id) {


    return this.http.get("/Consultation/DownloadPrescription/" + id);
  }



  checksession(id) {
    return this.http.get("/Consultation/CheckConsultationSession/" + id);
  }


  CheckConsultationCurrentStatus(id) {
    return this.http.get("/Consultation/CheckConsultationCurrentStatus/" + id);
  }



  Doctorlogout() {
    return this.http.get("/MemberProfile/Logout");
  }




  UpdateVCResponseTime(data: any) {

    return this.http.post("/Consultation/InsertUpdateVCResponseTime", data);
  }




  getCaseDetails(data: any) {
    return this.http.post("/eCollab/ShowCaseDetail", data);
  }
  GetReport(data: any) {
    return this.http.post("/eCollab/ShowReport", data);
  }


}
