import { Component, OnInit } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ExcelService } from '../../../_services/excel.service';
@Component({
  selector: 'app-printconsulationsummaryreport',
  templateUrl: './printconsulationsummaryreport.component.html'
})
export class PrintconsulationsummaryreportComponent implements OnInit {

  feacthchdata: any;
  searchdatalist: any;
  currentdate: Date = new Date();
  PrintSearchlst: any[];
  loading: boolean;
  constructor(private excelService: ExcelService) { }




  ngOnInit() {

    this.feacthchdata = window;

    if (this.feacthchdata.PrintedBy == undefined) {
      window.close()
    } else {

      this.searchdatalist = this.feacthchdata.searchdata
    }
  }



  downloadpdf() {



    var data = document.getElementById('print-section');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('Report.pdf'); // Generated PDF

    });
    //this.subscription.unsubscribe()
  }




  public captureScreen() {
    this.loading = true;
    this.PrintSearchlst=[];
    for (let i = 0; i < this.searchdatalist.length; i++) {

      var doctorprefix = "";

      if (this.searchdatalist[i].prefix == "Dr.") {
        doctorprefix = "Dr.";
      } else {
        doctorprefix = "";
      }
      let printjson = { "Sr No.": i+1," Health Facility": this.searchdatalist[i].institutionName,"District":this.searchdatalist[i].districtName, "User": doctorprefix+" "+this.searchdatalist[i].userName, "Total": this.searchdatalist[i].total, "Complete": this.searchdatalist[i].complete, "InProcess": this.searchdatalist[i].inProcess }
      this.PrintSearchlst.push(printjson);

    }


    this.excelService.exportAsExcelFile(this.PrintSearchlst, 'Consultation');
    this.loading = false;

  }
  
  printDiv() {

    window.print();
    return false;


  }


}
