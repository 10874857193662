import { Observable } from 'rxjs';
import { Message } from "./message";
import { User } from "./user";
import { ParticipantResponse } from "./participant-response";
import { IChatParticipant } from './chat-participant';

export abstract class ChatAdapter {
  // ### Abstract adapter methods ###

  public abstract listFriends(): Observable<ParticipantResponse[]>;

  public abstract getMessageHistory(destinataryId: any): Observable<Message[]>;

  public abstract sendMessage(message: Message): void;

  public abstract consultationCall(message: Message): void;

  public abstract vcCall(message: Message): void;

  public abstract shareCamera(strTo: string): void;
  // ### Adapter/Chat income/ingress events ###

  public onFriendsListChanged(participantsResponse: ParticipantResponse[]): void {
    this.friendsListChangedHandler(participantsResponse);
  }

  public onMessageReceived(participant: IChatParticipant, message: Message): void {
    this.messageReceivedHandler(participant, message);
  }

  
  public onConsultationReceived(participant: IChatParticipant, message: Message): void {
    this.consultationReceivedHandler(participant, message);
  }

  public onVCReceived(participant: IChatParticipant, message: Message): void {
    this.VCReceivedHandler(participant, message);
  }

  //Close Chat from Grid
  public onGridChatClosed(userId: any): void {
    this.CloseChatGrid(userId);
  }

  // Event handlers
  friendsListChangedHandler: (participantsResponse: ParticipantResponse[]) => void = (participantsResponse: ParticipantResponse[]) => { };
  messageReceivedHandler: (participant: IChatParticipant, message: Message) => void = (participant: IChatParticipant, message: Message) => { };

  consultationReceivedHandler: (participant: IChatParticipant, message: Message) => void = (participant: IChatParticipant, message: Message) => { };

  VCReceivedHandler: (participant: IChatParticipant, message: Message) => void = (participant: IChatParticipant, message: Message) => { };

  //Close Chat from Grid
  CloseChatGrid: (userId: any) => void = (userId: any) => { };

}
