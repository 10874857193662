import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app.routing';

import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';


import { CanDeactivateGuard } from './_guards/deactive.guard';

import { UserService, AuthenticationService } from './_services/admin/index';
import { HAuthenticationService } from './_services/hospital/index';

import { DUserService, DAuthenticationService } from './_services/doctor/index';
import { HUserService } from './_services/hospital/index';


import { PUserService, PAuthenticationService } from './_services/patient/index';

import { JwtInterceptor, ErrorInterceptor, AppConfig } from './_helpers';
import { AlertService, HttpClient, StorageService } from './_services';

import { AppLayoutComponent } from './components/_layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from './components/_layout/site-layout/site-layout.component';
import { HospitallayoutComponent } from './components/_layout//hospitallayout/hospitallayout.component';
import { DoctorlayoutComponent } from './components/_layout//doctorlayout/doctorlayout.component';

import { PatientlayoutComponent } from './components/_layout/patientlayout/patientlayout.component';

import { WBSiteLayoutComponent } from './components/_layout/wbsite-layout/wbsite-layout.component';


import { PrinthfreportComponent } from './components/printreport/printhfreport/printhfreport.component';
import { PrintproblemreportComponent } from './components/printreport/printproblemreport/printproblemreport.component';
import { PrintconsulationstatusreportComponent } from './components/printreport/printconsulationstatusreport/printconsulationstatusreport.component';
import { PrintconsulationsummaryreportComponent } from './components/printreport/printconsulationsummaryreport/printconsulationsummaryreport.component';
import { PunjabComponent } from './components/admin/punjab/punjab.component';
import { PrintdormantreportComponent } from './components/printreport/printdormantreport/printdormantreport.component';

import { RouterModule } from '@angular/router';


import { PrintPOSreportComponent } from './components/printreport/printPOS/printPOSreport.component'

import { AutocompleteLibModule } from 'angular-ng-autocomplete';

//import { DoctorlayoutComponent } from './components/_layout/Doctorlayout/Doctorlayout.component';
import { NgDatepickerModule } from 'ng2-datepicker';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ng6-toastr-notifications';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatMenuModule, MatSidenavModule, MatSelectModule, MatNativeDateModule, MatDatepickerModule, MatRadioModule, MatTableModule, MatTabsModule,
  MatToolbarModule, MatIconModule, MatCardModule} from '@angular/material';
import { NgChatModule } from './ng-chat';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AngularDraggableModule } from 'angular2-draggable';
import { DwvModule } from './components/dicomviewer/dicomviewer.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { UserIdleModule } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';
import { ExcelService } from './_services/excel.service';
import { ReportService } from './_services/report.service';
import { NgxPaginationModule } from 'ngx-pagination';


import { AppServices } from './_services/setting.service';



import { OwlModule } from 'ngx-owl-carousel';
import { PrintenrollmentstatusComponent } from './components/printreport/ncd/printenrollmentstatus/printenrollmentstatus.component';
//import { NcdlayoutComponent } from './components/_layout/ncdlayout/ncdlayout.component';



@NgModule({
  declarations: [

    AppComponent,

    AlertComponent,

    AppLayoutComponent,
    SiteLayoutComponent,
    HospitallayoutComponent,
    DoctorlayoutComponent, WBSiteLayoutComponent,
    PrinthfreportComponent, PatientlayoutComponent,
    PrintproblemreportComponent,
    PrintconsulationstatusreportComponent,
    PrintconsulationsummaryreportComponent,
    PunjabComponent,
    PrintdormantreportComponent,
    PrintPOSreportComponent,
    PrintenrollmentstatusComponent,
    //NcdlayoutComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule, OwlModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatSelectModule,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTableModule,
    MatTabsModule,
    ToastrModule.forRoot(),
    NgChatModule,
    TooltipModule,
    RouterModule.forRoot([]),
    NgxLoadingModule.forRoot({}),
    AngularDraggableModule,
    DwvModule, SweetAlert2Module, NgDatepickerModule, AutocompleteLibModule, NgxCaptchaModule,
    UserIdleModule.forRoot({ idle: 600, timeout: 300, ping: 2 }),
    NgxPaginationModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule
  ],
  providers: [
    AppConfig,
    AuthGuard, CanDeactivateGuard,
    AlertService,
    HttpClient,
    StorageService,
    AuthenticationService,
    UserService,
    HAuthenticationService, DUserService, DAuthenticationService, AppServices,HUserService, CookieService, ExcelService, PUserService, PAuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
