import { Injectable } from '@angular/core';
import { Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService } from './../_services/storage.service';
import { Observable } from "rxjs";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Message } from '../ng-chat/core/message';
import { DUserService } from '../_services/doctor/index';
import { first, filter } from 'rxjs/operators';
export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()


export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {


    constructor(
        private router: Router,
        public storageService: StorageService,
        private _userService: DUserService,
    ) { }


    canDeactivate(component: CanComponentDeactivate,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ) {

        ///////////////last url///////////////
        let lasturl: string = state.url;
        const role = route.data.role;

        ////////////////////////////next url////////////////
        let nexturl: string = nextState.url;


        if (role == "Speciality") {

            if (this.storageService.isConsultationActive == true) {
                Swal.fire({
                    title: '',
                    text: "Do you want to Leave Consultation?",
                    showCancelButton: true,
                    confirmButtonColor: '#133656',
                    cancelButtonColor: '#E5C151',
                    confirmButtonText: 'Yes',
                    cancelButtonText: "No",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false

                }).then((result) => {


                    if (result.value) {

                        if (this.storageService.SenderId != undefined && this.storageService.SenderId != null && this.storageService.SenderId != 0) {


                            this._userService.UpdateVCResponseTime({ "ConsultationId": this.storageService.consultationId, "FromMemberId": this.storageService.ReceiverId, "ToMemberId": this.storageService.SenderId })
                                .pipe(first())
                                .subscribe(
                                    data => {

                                        /////////////////////////////////////leave page
                                        let message = new Message();
                                        message.fromId = this.storageService.SenderId;
                                        message.toId = this.storageService.ReceiverId;

                                        if (this.storageService.SenderId != 0 && this.storageService.ReceiverId != 0) {

                                            this.storageService.hubConnection.send("SpecialistLeaveCall", message);
                                        }

                                        window.location.href = nexturl;
                                        this.storageService.SignalRAdapter.CloseChatGrid(this.storageService.otherEndChatId);
                                        this.storageService.isConnectionEstablished = false;
                                        this.storageService.isConsultationActive= false;
                                        this.storageService.SignalRAdapter.LeaveConnference();


                                    })


                        }


                    } else {
                        return true;
                    }


                })
                return false;
            }
            else {

                return true;
            }
        } if (role == "Remote") {





            var mesage = "";
            if (this.storageService.isConsultationActive == true) {
                Swal.fire({
                    title: '',
                    text: "Do you want to Leave Consultation?",
                    showCancelButton: true,
                    confirmButtonColor: '#133656',
                    cancelButtonColor: '#E5C151',
                    confirmButtonText: 'Yes',
                    cancelButtonText: "No",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false

                }).then((result) => {
                    if (result.value) {




                        if (this.storageService.SenderId != undefined && this.storageService.SenderId != null && this.storageService.SenderId != 0) {


                            this._userService.UpdateVCResponseTime({ "ConsultationId": this.storageService.consultationId, "FromMemberId": this.storageService.SenderId, "ToMemberId": this.storageService.ReceiverId })
                                .pipe(first())
                                .subscribe(
                                    data => {

                                        /////////////////////////////////////leave page
                                        let message = new Message();
                                        message.fromId = this.storageService.SenderId;
                                        message.toId = this.storageService.ReceiverId;
                                        if (this.storageService.SenderId != 0 && this.storageService.ReceiverId != 0) {
                                            this.storageService.hubConnection.send("RemoteLeaveCall", message);
                                        }



                                        //////////////////////////

                                        window.location.href = nexturl;
                                        this.storageService.SignalRAdapter.CloseChatGrid(this.storageService.otherEndChatId);
                                        this.storageService.isConnectionEstablished = false;
                                        this.storageService.isConsultationActive= false;
                                        this.storageService.SignalRAdapter.LeaveConnference();
                                    })
                        }

                    } else {
                        return false;
                    }
                })

                return false;
            } else if (this.storageService.isConsultationActive == false &&    this.storageService.isDraftStatus == true ) {
                Swal.fire({
                    title: '',
                    text: "Save as Draft?",
                    showCancelButton: true,
                    confirmButtonColor: '#133656',
                    cancelButtonColor: '#E5C151',
                    confirmButtonText: 'Yes',
                    cancelButtonText: "No",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false

                }).then((result) => {
                    if (result.value) {

                        return false;

                    } else {
                        window.location.href = nexturl;
                        this.storageService.SignalRAdapter.CloseChatGrid(this.storageService.otherEndChatId);
                        this.storageService.isConnectionEstablished = false;
                        this.storageService.isConsultationActive= false;
                        this.storageService.SignalRAdapter.LeaveConnference();
                    }
                })


                return false;

            } else {
                return true;
            }

        } else {
            return true;
        }
    }
}


