import { Injectable } from '@angular/core';
import {  HttpClient } from '../../_services/index';
import { AppConfig } from '../../_helpers/config';

@Injectable({
  providedIn: 'root'
})
export class MapSpecificationsService {
	chartAvgConsultation : any;
	chartTotalHealth  :any;
	chartTotalConsultation : any;
	chartTotalPatients : any;
	chartTotalPrescriptions : any;
	chartTotalReferals : any;
	color : any;

  constructor(private http: HttpClient, config: AppConfig) {

this.chartAvgConsultation = 
{
        
        "caption": "Average Consulation in the Country",
  "indecimalseparator": ",",
  "decimalseparator": ".",
  "theme": "fusion",
  showlabels: 0,
  showLegend : 1,
  baseFont : "roboto",
  // exportEnabled : 1,
  // exportFileName : "esanjeevani",
  // showToolTip : 1,
  // legendAllowDrag : 1,
 captionFontSize : 15,
  fontColor : "#fff",
 legendShadow : 1,
 legendBgColor : "#ccc",
 // formatNumber : 1,
 // showCanvasBorder : 1,
 // bgColor : "#ccc",

  // "includevalueinlabels": "1",
  "legendposition": "Bottom",
  // "legendcaption": "Number of registered instituitons",
    // "basefontcolor": "rgb(90, 90, 90)",
    baseFontSize : 12,
    "entitytooltext":
      "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black;'><b>$datavalue</b> registered institutes</div>",
    "entityfillhovercolor": "#BBDEFB"
}

this.chartTotalHealth = 
{
        "caption": "Total Health Facilities in the Country",
       
  "indecimalseparator": ",",
  "decimalseparator": ".",
  "theme": "fusion",
  showlabels: 0,
  showLegend : 1,
   baseFont : "roboto",
  // exportEnabled : 1,
  // exportFileName : "esanjeevani",
  // showToolTip : 1,
  // legendAllowDrag : 1,
 captionFontSize : 15,
 // fontColor : "#fff",
 legendShadow : 1,
 legendBgColor : "#ccc",
 // formatNumber : 1,
 // showCanvasBorder : 1,
 // bgColor : "#ccc",

  // "includevalueinlabels": "1",
  "legendposition": "Bottom",
  // "legendcaption": "Number of registered instituitons",
    // "basefontcolor": "#000000",
    baseFontSize : 12,
    "entitytooltext":
      "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black;'><b>$datavalue</b> registered institutes</div>",
    "entityfillhovercolor": "#BBDEFB"
}

this.chartTotalConsultation = 
{
        "caption": "Total Consultations in the Country",
     
  "indecimalseparator": ",",
  "decimalseparator": ".",
  "theme": "fusion",
  showlabels: 0,
  showLegend : 1,
   baseFont : "roboto",
  // exportEnabled : 1,
  // exportFileName : "esanjeevani",
  // showToolTip : 1,
  // legendAllowDrag : 1,
 captionFontSize : 15,
 // fontColor : "#fff",
 legendShadow : 1,
 legendBgColor : "#ccc",
 // formatNumber : 1,
 // showCanvasBorder : 1,
 // bgColor : "#ccc",

  // "includevalueinlabels": "1",
  "legendposition": "Bottom",
  // "legendcaption": "Number of registered instituitons",
    // "basefontcolor": "#000000",
    baseFontSize : 12,
    "entitytooltext":
      "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black;'><b>$datavalue</b> registered institutes</div>",
    "entityfillhovercolor": "#BBDEFB"
}

this.chartTotalPatients = 
{
       "caption": "Total Patients in the Country",
       // "caption": "Average Consulation in the Country",
  "indecimalseparator": ",",
  "decimalseparator": ".",
  "theme": "fusion",
  showlabels: 0,
  showLegend : 1,
   baseFont : "roboto",
  // exportEnabled : 1,
  // exportFileName : "esanjeevani",
  // showToolTip : 1,
  // legendAllowDrag : 1,
 captionFontSize : 15,
 // fontColor : "#fff",
 legendShadow : 1,
 legendBgColor : "#ccc",
 // formatNumber : 1,
 // showCanvasBorder : 1,
 // bgColor : "#ccc",

  // "includevalueinlabels": "1",
  "legendposition": "Bottom",
  // "legendcaption": "Number of registered instituitons",
    // "basefontcolor": "#000000",
    baseFontSize : 12,
    "entitytooltext":
      "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black;'><b>$datavalue</b> registered institutes</div>",
    "entityfillhovercolor": "#BBDEFB"
}
this.chartTotalPrescriptions = 
{
        
      "caption": "Total Prescriptions in the Country",
  "indecimalseparator": ",",
  "decimalseparator": ".",
  "theme": "fusion",
  showlabels: 0,
  showLegend : 1,
   baseFont : "roboto",
  // exportEnabled : 1,
  // exportFileName : "esanjeevani",
  // showToolTip : 1,
  // legendAllowDrag : 1,
 captionFontSize : 15,
 // fontColor : "#fff",
 legendShadow : 1,
 legendBgColor : "#ccc",
 // formatNumber : 1,
 // showCanvasBorder : 1,
 // bgColor : "#ccc",

  // "includevalueinlabels": "1",
  "legendposition": "Bottom",
  // "legendcaption": "Number of registered instituitons",
    // "basefontcolor": "#000000",
    baseFontSize : 12,
    "entitytooltext":
      "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black;'><b>$datavalue</b> registered institutes</div>",
    "entityfillhovercolor": "#BBDEFB"
}
this.chartTotalReferals = 
{
        "caption": "Total Referals in the Country",
     
  "indecimalseparator": ",",
  "decimalseparator": ".",
  "theme": "fusion",
  showlabels: 0,
  showLegend : 1,
    baseFont : "roboto", // exportEnabled : 1,
  // exportFileName : "esanjeevani",
  // showToolTip : 1,
  // legendAllowDrag : 1,
 captionFontSize : 15,
 // fontColor : "#fff",
 legendShadow : 1,
 legendBgColor : "#ccc",
 // formatNumber : 1,
 // showCanvasBorder : 1,
 // bgColor : "#ccc",

  // "includevalueinlabels": "1",
  "legendposition": "Bottom",
  // "legendcaption": "Number of registered instituitons",
    // "basefontcolor": "#000000",
    baseFontSize : 12,
    "entitytooltext":
      "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black;'><b>$datavalue</b> registered institutes</div>",
    "entityfillhovercolor": "#BBDEFB"
}


      this.color =  [{
		"minvalue": "0",
		"maxvalue": "500",
		"displayvalue": "< 500",
		"code": "#FFEE75"
	},
	{
		"minvalue": "500",
		"maxvalue": "1000",
		"displayvalue": "500 - 1000",
		"code": "#FFD800"
	},
	{
		"minvalue": "1000",
		"maxvalue": "1500",
		"displayvalue": "1000 - 2000",
		"code": "#FFBA00"
	},
	{
		"minvalue": "1500",
		"maxvalue": "2000",
		"displayvalue": "2000 - 3000",
		"code": "#EF9B0F"
	},
	{
		"minvalue": "2000",
		"maxvalue": "5000",
		"displayvalue": "No data availale",
		"code": "#BBDEFB"
	}
]
      


  }

  chartCheck(){
  
  	return this.chartAvgConsultation;	
  
  	// if(value == 2){
  	// 	return this.chartTotalHealth;
  	// }
  	// if(value == 3){
  	// 	return this.chartTotalConsultation;

  	// }
  	// if(value == 4){
  	// 	return this.chartTotalPatients;

  	// }
  	// if(value == 5){
  	// 	return this.chartTotalPrescriptions;

  	// }
  	
  	// if(value == 6){
  	// 	return this.chartTotalReferals;

  	// }
  }

   chartCheck2(){
return this.chartTotalHealth;
   }

   chartCheck3(){
return this.chartTotalConsultation;
   }
      chartCheck4(){
return this.chartTotalPrescriptions;
   }
      chartCheck5(){
return this.chartTotalPatients;
   }
      chartCheck6(){
return this.chartTotalReferals;
   }
  colorCHECK() {
 return this.color;
 }


}
